import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    Input,
    Label,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import Map, {Layer, Marker, NavigationControl, ScaleControl, Source} from "react-map-gl/maplibre";
import Pin from "./pin";
import circle from "@turf/circle";
import LoadStatus from "../Loads/LoadFunctions";

moment.tz.setDefault("America/Halifax");

class LocationDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mapRef: null,
            markerRef: null,
            location_id: null,
            details: {
                loading: true,
                name: "",
                address: "",
                province: "",
                city: "",
                postal: "",
                country: "",
                lat: "",
                lon: "",
                radius: 0,
                is_stop: 1
            },
            radius_options: [50, 75, 100, 150, 175, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1250, 1500],
            circle: {"type": "Feature", "properties": {"foo": "bar"}, "geometry": {"type": "Polygon", "coordinates": []}},
            trips: {
                list: [],
                loading: true,
            },
        };
        this.updateValue = this.updateValue.bind(this);
    }

    onMapRefChange = node => {
        this.setState({mapRef: node});
    };

    onMarkerRefChange = node => {
        this.setState({markerRef: node});
    };

    changeRadius = (radius) => {
        var self = this;
        var details = this.state.details;
        details['radius'] = radius
        this.setState({
            circle: circle([details.lon, details.lat], radius, {steps: 50, units: 'meters', properties: {foo: 'bar'}}),
            details: details
        }, function () {
            self.state.markerRef.setLngLat([this.state.details.lon, this.state.details.lat]);
            self.saveDetails();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('updated')
        if (typeof this.props.location_id != "undefined" && this.props.location_id != false && this.props.location_id !== this.state.location_id) {
            console.log('location updated')
            console.log(this.props.location_id)
            this.setState({location_id: this.props.location_id})
            var self = this;
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + '/location/' + self.props.location_id)
                .then(function (response) {
                    response.data.loading = false;
                    self.setState({
                        circle: circle([response.data.lon, response.data.lat], response.data.radius, {steps: 50, units: 'meters', properties: {foo: 'bar'}}),
                        details: response.data,
                    });
                    setTimeout(() => {
                        self.state.mapRef.setCenter([response.data.lon, response.data.lat]);
                        self.state.markerRef.setLngLat([response.data.lon, response.data.lat]);
                    }, 1000);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    }

    saveDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/location/' + this.props.location_id, {data: this.state.details})
            .then(function (response) {
                console.log('it saved')
                console.log(typeof self.props.update_locations)
                if (typeof self.props.update_locations == "function") {
                    self.props.update_locations()
                }
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    updateValue(event, name, value) {
        var self = this;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        var details = this.state.details;
        details[name] = value;
        this.setState({
            details: details
        }, () => {
            self.saveDetails();
        });
    }

    render() {
        return (
            <>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.details.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal">
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Name</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="name" value={this.state.details.name} required onChange={this.updateValue}/>
                                        </div>
                                    </Row>
                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Address</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="address" value={this.state.details.address} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">City</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="city" value={this.state.details.city} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Province</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="province" value={this.state.details.province} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Postal</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="postal_code" value={this.state.details.postal_code} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                        <Row className="mb-1">
                                            <label className="col-xl-3 col-form-label pt-0">Country</label>
                                            <div className="col-xl-9">
                                                <Input size="sm" type="text" name="country" value={this.state.details.country} required onChange={this.updateValue}/>
                                            </div>
                                        </Row>
                                    </div>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0"></label>
                                        <div className="col-xl-9">
                                            <Label className="mb-0 text-bold" onClick={(e) => {
                                                this.updateValue(e, 'is_stop', 0)
                                            }}>
                                                <Input type="checkbox" name="status" value="1"
                                                       checked={(this.state.details.is_stop == 0)}
                                                />
                                                <i className="far fa-hourglass text-success"></i> NOT a stop or destination (e.g. toll booth, traffic)
                                            </Label>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0"></label>
                                        <div className="col-xl-9">
                                            <Label className="mb-0 text-bold" onClick={(e) => {
                                                this.updateValue(e, 'is_stop', 1)
                                            }}>
                                                <Input type="checkbox" name="status" value="1"
                                                       checked={(this.state.details.is_stop == 1)}
                                                />
                                                <i className="fas fa-hourglass-half text-warning"></i> Location is a stop (e.g. Irving, Tim Hortons)
                                            </Label>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0"></label>
                                        <div className="col-xl-9">
                                            <Label className="mb-0 text-bold" onClick={(e) => {
                                                this.updateValue(e, 'is_stop', 2)
                                            }}>
                                                <Input type="checkbox" name="status" value="1"
                                                       checked={(this.state.details.is_stop == 2)}
                                                />
                                                <i className="fas fa-hourglass-end text-danger"></i> Location is a destination (e.g. customer location)
                                            </Label>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className={(this.state.details.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <div style={{height: '300px', width: '100%', position: 'relative'}}>
                                    <Map
                                        ref={this.onMapRefChange}
                                        initialViewState={{
                                            longitude: 0,
                                            latitude: 0,
                                            zoom: 16,
                                            bearing: 0,
                                            pitch: 0,
                                            touchZoomRotate: true,
                                            dragRotate: false,
                                            showCompass: false,
                                            visualizePitch: false,
                                            attributionControl: false,
                                        }}
                                        mapStyle="https://api.maptiler.com/maps/streets-v2/style.json?key=6P8HshICDGDXoVd9nuYs"
                                    >
                                        <NavigationControl position="top-right" showCompass={false} visualizePitch={false}/>
                                        <ScaleControl/>
                                        <Marker
                                            ref={this.onMarkerRefChange}
                                            longitude={-100}
                                            latitude={40}
                                            anchor="bottom"
                                        >
                                            <Pin size={20}/>
                                        </Marker>
                                        <Source id="my-data" type="geojson" data={this.state.circle}>
                                            <Layer
                                                id="point-90-hi"
                                                type="fill"
                                                paint={{
                                                    "fill-color": "#088",
                                                    "fill-opacity": 0.4,
                                                    "fill-outline-color": "yellow"
                                                }}
                                            />
                                        </Source>
                                    </Map>
                                    <div style={{position: "absolute", top: "0px"}}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                Geofence: {this.state.details.radius}m
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.radius_options).map((key, i) => {
                                                    return (
                                                        <DropdownItem key={key}
                                                                      onClick={() => this.changeRadius(this.state.radius_options[key])}>{this.state.radius_options[key]}m</DropdownItem>
                                                    )
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                </div>
                                <iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                        src={"https://www.google.com/maps?q=" + this.state.details.lat + "," + this.state.details.lon + "&hl=es;z%3D14&amp&t=h&output=embed"}></iframe>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className={(this.props.show_trips == true ? "" : "d-none")}>
                    <Card className={(this.state.trips.loading ? "card card-default whirl traditional" : "card card-default")}>
                        <CardBody>
                            <p className="lead bb">{this.state.trips.list.length} Trips</p>
                            <div className="table-responsive">
                                <table className={(this.state.trips.list.length > 0 && !this.state.trips.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-lg-table-cell">#</th>
                                        <th className="d-none d-md-table-cell">Date</th>
                                        <th className="d-none d-md-table-cell">Origin</th>
                                        <th className="d-none d-lg-table-cell">Destination</th>
                                        <th className="d-none d-lg-table-cell">Customer</th>
                                        <th className="d-none d-lg-table-cell">Driver</th>
                                        <th className="d-none d-lg-table-cell">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.trips.list.map(function (item, key) {
                                        return (
                                            <tr key={key} onClick={this.goToLoad.bind(this, item.load_id)}>
                                                <td className="d-none d-lg-table-cell">{item.load_id}</td>
                                                <td className="d-none d-md-table-cell">{moment(item.start).format("MMM. D/YY HH:mm")}</td>
                                                <td className="d-none d-md-table-cell">{(item.origin != null && typeof this.state.locations[item.origin] != "undefined" ? this.state.locations[item.origin].name : "")}</td>
                                                <td className="d-none d-md-table-cell">{(item.destination != null && typeof this.state.locations[item.destination] != "undefined" ? this.state.locations[item.destination].name : "")}</td>
                                                <td className="d-none d-lg-table-cell">{(item.customer_id != null && typeof this.state.customers[item.customer_id] != "undefined" ? this.state.customers[item.customer_id].name : "")}</td>
                                                <td className="d-none d-lg-table-cell">{(item.driver_id != null && typeof this.state.drivers[item.driver_id] != "undefined" ? this.state.drivers[item.driver_id].first_name + " " + this.state.drivers[item.driver_id].last_name : "")}</td>
                                                <td><LoadStatus status={item.status}/></td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
                <ToastContainer stacked={true}/>
            </>
        );
    }
}

export default LocationDisplay;