import React from "react";
import axios from 'axios';
import {API_ROOT} from '../../api-config';

export default async function getCustomers(response) {
    axios.defaults.withCredentials = true;
    await axios.get(`${API_ROOT}/customers`, {withCredentials: true})
        .then(async function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(async function (error) {
            response({});
        });
}