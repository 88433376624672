import React, {Component} from 'react';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import "react-datepicker/dist/react-datepicker.css";
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';

const AddAttachment = (props) => {
    const getUploadParams = ({file, meta}) => {
        const url = API_ROOT + '/messages/attachment';
        const body = new FormData();
        body.append('file', file);
        return {url, body}
    };

    const handleChangeStatus = ({meta, remove, xhr}, status) => {
        if (status === 'done') {
            var response = JSON.parse(xhr.response);
            props.attachmentCallback({name: response.name, hash: response.hash, path: response.path});
        } else if (status === 'headers_received') {
            remove()
        } else if (status === 'aborted') {
            Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept=".mov,.mp4,.tiff,.gif,.png,.jpg,.jpeg,.pdf"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'Image or PDF files only' : "Click or Drag & Drop Photo / PDF")}
                styles={{
                    inputLabel: {fontSize: "14px", fontWeight: "normal"},
                    dropzone: {height: 30, minHeight: 30, border: "1px dashed #ccc",},
                    dropzoneActive: {borderColor: 'green'},
                }}
            />
        </React.Fragment>
    )
};

export default (AddAttachment);