import * as React from 'react';
import {useMemo, useEffect, useRef, useCallback} from 'react';
import Map, {
    Marker,
    ScaleControl,
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import Pin from '../Map/pin';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");

export function MapLoad(props) {

    const mapRef = useRef(null);

    const setBounds = useCallback((bounds) => {
        mapRef.current?.fitBounds([
            [bounds.x + .015, bounds.y - .015], // sw
            [bounds.x - .015, bounds.y + .015] // ne
        ], {padding: 50});
    }, []);

    useEffect(() => {
        setTimeout(function () {
            if (typeof props.point.lon != "undefined") {
                setBounds({x: props.point.lon, y: props.point.lat})
            }
        }, 100);
    }, [props.point])

    var pins = useMemo(
        () => (
            <Marker
                longitude={Number((typeof props.point.lon == "undefined" ? 0 : props.point.lon))}
                latitude={Number((typeof props.point.lat == "undefined" ? 0 : props.point.lat))}
                anchor={"center"}
            >
                <Pin speed={props.point.speed} dir={props.point.dir} is_ignit={props.point.is_ignit} timestamp={props.point.timestamp}
                     timestamp_map={moment().unix()} name={'truck'} show_names={false}
                     show_active={false}/>
            </Marker>
        ),
        [props.point]
    );

    return (
        <>
            <Map
                ref={mapRef}
                initialViewState={{
                    longitude: -64.842166,
                    latitude: 46.085063,
                    zoom: 8,
                    bearing: 0,
                    pitch: 0,
                    touchZoomRotate: true,
                    dragRotate: false,
                    showCompass: false,
                    visualizePitch: false,
                    attributionControl: false,
                    height: '250px'
                }}
                mapStyle="https://api.maptiler.com/maps/streets-v2/style.json?key=6P8HshICDGDXoVd9nuYs"
            >
                <ScaleControl/>
                {pins}
            </Map>
        </>
    );
}