import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input, InputGroup, InputGroupAddon
} from 'reactstrap';

import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

import moment from 'moment';
import 'moment-timezone';
import SingleDatePicker from "react-bootstrap-daterangepicker";
import AddAttachment from "../Maintenance/AddAttachment";

moment.tz.setDefault("America/Halifax");

class AddMaintenance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            maint_id: null,
            date_enter: moment(),
            equipment_id: null,
            date_completed: null,
            category: "(select)",
            description: "",
            cause: "",
            urgency: 0,
            starting_urgency: 0,
            vendor_name: "",
            cost_est: 0,
            cost_actual: null,
            status: 0,
            starting_status: 0,
            reminder_date: null,
            reminder_text: "",
            processing: false,
            attachments: [],
            urgencies: {0: "Low", 1: "Medium", 2: "High (OOS)"},
            statuses: {0: "Pending Auth.", 1: "Declined", 2: "Approved", 3: "Completed, Not Billed", 4: "Completed"},
            categories: {1: "Safety Inspection", 2: "Registration", 3: "Preventative Maintenance", 4: "Reactive Maintenance", 5: "Accident Repair", 6: "Other"}
        };
    }

    componentDidMount() {
        this.setState({
            equipment_id: this.props.equipment_id
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.maint_id != "undefined" && prevProps.maint_id !== this.props.maint_id && this.props.maint_id != null) {
            var self = this;
            axios.get(API_ROOT + "/maintenance/?maint_id=" + this.props.maint_id)
                .then(function (response) {
                    response.data.loading = false;
                    self.setState({
                        date_enter: moment(response.data[0].date_enter),
                        date_completed: (response.data[0].date_completed == null ? null : moment(response.data[0].date_completed)),
                        category: response.data[0].category,
                        description: response.data[0].description,
                        cause: response.data[0].cause,
                        urgency: response.data[0].urgency,
                        starting_urgency: response.data[0].urgency,
                        vendor_name: response.data[0].vendor_name,
                        cost_est: response.data[0].cost_est,
                        cost_actual: response.data[0].cost_actual,
                        status: response.data[0].status,
                        starting_status: response.data[0].status,
                        attachments: response.data[0].attachments,
                        equipment_id: response.data[0].equipment_id,
                        reminder_date: (response.data[0].reminder_date == null ? null : moment(response.data[0].reminder_date)),
                        reminder_text: response.data[0].reminder_text,
                        maint_id: self.props.maint_id,
                        processing: false,
                    });
                })
            this.setState({
                modal: true
            });
        }
    }

    attachmentCallback = (details) => {
        this.setState({
            file: this.state.attachments.push(details)
        });
    };
    getAttachment = (name, hash) => {
        var element = document.createElement('a');
        element.setAttribute('href', "attachments/" + hash);
        if ("download" in document.createElement("a")) {
            element.setAttribute('download', name);
        }
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            maint_id: null,
            equipment_id: null,
            date_completed: null,
            category: "(select)",
            description: "",
            cause: "",
            urgency: 0,
            starting_urgency: 0,
            vendor_name: "",
            cost_est: 0,
            cost_actual: null,
            status: 0,
            starting_status: 0,
            reminder_date: null,
            reminder_text: "",
            processing: false,
            attachments: [],
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string" && typeof value.target != "undefined") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    updateReminderDate = (event, picker) => {
        if (event.type == "apply") {
            this.setState({
                reminder_date: moment(picker.startDate),
            });
        }
    }

    updateCompletedDate = (event, picker) => {
        if (event.type == "apply") {
            this.setState({
                date_completed: moment(picker.startDate),
            });
        }
    }


    SaveMaintenance = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/maintenance/' + this.props.maint_id, this.state)
            .then(function (response) {
                self.setState({
                    maint_id: response.data.maint_id,
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
                if (response.data.Message != "") {
                    setTimeout(function () {
                        window.location.reload(1);
                    }, 5000);
                } else {
                    if (typeof self.props.refreshMaintenance != "undefined") {
                        self.props.refreshMaintenance();
                    }
                }
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <>
                <Button className={this.props.hidden ? "d-none" : "mt-0"} color="primary" size="xs" onClick={this.toggle}><i className="fas fa-plus"></i> Maintenance</Button>
                <FormGroup>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                        <ModalHeader>
                            <div className="h3 mb-0">Maintenance Item</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row className="mb-0">
                                    <Label sm={4} lg={3} className="pt-0">Date Entered:</Label>
                                    <Col sm={8} lg={9}>
                                        {this.state.date_enter.format('MMM. D, YYYY')}
                                    </Col>
                                </FormGroup>
                                <FormGroup row className={(typeof this.props.equipment != "undefined" ? "mb-1" : "d-none")}>
                                    <Label sm={4} lg={3}>Equipment:</Label>
                                    <Col sm={8} lg={9}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {(typeof this.props.equipment != "undefined" && typeof this.props.equipment[this.state.equipment_id] != "undefined" ? this.props.equipment[this.state.equipment_id].name : "(select)")}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {typeof this.props.equipment != "undefined" && Object.keys(this.props.equipment).map((key, i) => {
                                                    return (<DropdownItem key={key}
                                                                          onClick={() => this.updateField('equipment_id', this.props.equipment[key].equipment_id)}>{this.props.equipment[key].name}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} lg={3}>Category:</Label>
                                    <Col sm={8} lg={9}>
                                        <UncontrolledButtonDropdown className="mr-1">
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.category}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.categories).map((key, i) => {
                                                    return (<DropdownItem key={key}
                                                                          onClick={() => this.updateField('category', this.state.categories[key])}>{this.state.categories[key]}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} lg={3}>Description:</Label>
                                    <Col sm={8} lg={9}>
                                        <Input type="textarea" size="sm" name="description" value={this.state.description}
                                               onChange={(event) => this.updateField("description", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} lg={3}>Cause:</Label>
                                    <Col sm={8} lg={9}>
                                        <Input type="textarea" size="sm" name="cause" value={this.state.cause}
                                               onChange={(event) => this.updateField("cause", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} lg={3}>Urgency:</Label>
                                    <Col sm={8} lg={9}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.urgencies[this.state.urgency]}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.urgencies).map((key, i) => {
                                                    return (<DropdownItem key={key} onClick={() => this.updateField('urgency', key)}>{this.state.urgencies[key]}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <span className={(this.state.starting_urgency != 2 && this.state.urgency == 2 ? "text-danger small" : "d-none")}><i
                                            className="fas fa-exclamation-triangle"></i> Equipment will be automatically marked OOS</span>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} lg={3}>Vendor:</Label>
                                    <Col sm={8} lg={9}>
                                        <Input type="text" size="sm" name="vendor_name" value={this.state.vendor_name}
                                               onChange={(event) => this.updateField("vendor_name", event)}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label sm={4} lg={3} className="pt-0">Estimated Cost:
                                        <div className="text-muted small" style={{marginTop: "-5px"}}>(do not include HST)</div>
                                    </Label>
                                    <Col sm={8} lg={9}>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="cost_est" className="text-right" value={this.state.cost_est}
                                                   onChange={(event) => this.updateField("cost_est", event)}/>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-0">
                                    <Label sm={4} lg={3} className="pt-0">Actual Cost:
                                        <div className="text-muted small" style={{marginTop: "-5px"}}>(do not include HST)</div>
                                    </Label>
                                    <Col sm={8} lg={9}>
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="append">$</InputGroupAddon>
                                            <Input type="text" name="cost_actual" className="text-right" value={this.state.cost_actual}
                                                   onChange={(event) => this.updateField("cost_actual", event)}/>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="mb-1">
                                    <Label sm={4} lg={3}>Status:</Label>
                                    <Col sm={8} lg={9}>
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                {this.state.statuses[this.state.status]}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {Object.keys(this.state.statuses).map((key, i) => {
                                                    return (<DropdownItem key={key} onClick={() => this.updateField('status', key)}>{this.state.statuses[key]}</DropdownItem>)
                                                })}
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                        <span className={(this.state.starting_status != 3 && this.state.status == 3 && this.state.urgency == 2 ? "text-info small" : "d-none")}><i
                                            className="fas fa-exclamation-triangle"></i> Equipment will be automatically marked as in service</span>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className={(this.state.status == 3 ? "mb-1" : "d-none")}>
                                    <Label sm={4} lg={3}>Date Completed:</Label>
                                    <Col sm={8} lg={9}>
                                        <SingleDatePicker
                                            onEvent={this.updateCompletedDate}
                                            date={(this.state.date_completed == null ? moment() : this.state.date_completed)}
                                            singleDatePicker={true}
                                            autoUpdateInput={true}
                                        >
                                            <div className="btn btn-light btn-sm">
                                                {(this.state.date_completed == null ? '(select)' : this.state.date_completed.format('MMM. D, YYYY'))}
                                            </div>
                                        </SingleDatePicker>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={4} lg={3}>Attachments:</Label>
                                    <Col sm={8} lg={9}>
                                        <div className={(this.props.maint_id > 0 ? "d-none" : "small text-muted")}>Save this item before adding attachments</div>
                                        <div className={(this.props.maint_id > 0 ? "" : "d-none")}>
                                            {this.state.attachments.map(function (attachment, attachment_num) {
                                                return (
                                                    <div className="btn btn-sm btn-link" onClick={(event) => this.getAttachment(attachment.name, attachment.hash)}>
                                                        {attachment.name}
                                                    </div>
                                                )
                                            }, this)}
                                            <AddAttachment {...this.state} attachmentCallback={this.attachmentCallback}/>
                                        </div>
                                    </Col>
                                </FormGroup>
                                <div className="border m-0 p-1 mb-1 bg-light rounded">
                                    <div className="row pb-1">
                                        <label className="col-xl-4 col-form-label pt-0"></label>
                                        <div className="col-xl-8 small text-bold">Follow-Up Reminder:</div>
                                    </div>
                                    <FormGroup row className="mb-1">
                                        <Label sm={4} lg={3}>Alert Date:</Label>
                                        <Col sm={8} lg={9}>
                                            <SingleDatePicker
                                                onEvent={this.updateReminderDate}
                                                date={(this.state.reminder_date == null ? moment() : this.state.reminder_date)}
                                                singleDatePicker={true}
                                                autoUpdateInput={true}
                                            >
                                                <div className="btn btn-light btn-sm">
                                                    {(this.state.reminder_date == null ? '(select)' : this.state.reminder_date.format('MMM. D, YYYY'))}
                                                </div>
                                            </SingleDatePicker>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={4} lg={3}>Message:</Label>
                                        <Col sm={8} lg={9}>
                                            <Input type="text" size="sm" name="reminder_text" value={this.state.reminder_text}
                                                   onChange={(event) => this.updateField("reminder_text", event)}/>
                                        </Col>
                                    </FormGroup>
                                </div>
                            </Form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.SaveMaintenance}><i
                                className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save</Button>
                        </ModalFooter>
                    </Modal>
                </FormGroup>
            </>
        );
    };
}

export default (AddMaintenance);
