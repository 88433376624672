import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input
} from 'reactstrap';
import getCustomers from "../Customers/CustomerFunctions";

import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            name: "",
            processing: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.triggerOpen != "undefined" && prevProps.triggerOpen !== this.props.triggerOpen && this.props.triggerOpen != false) {
            this.setState({
                modal: true
            });
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    AddCustomer = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/customer', this.state)
            .then(function (response) {
                self.setState({
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
                if (self.props.goToCustomer != null) {
                    self.props.goToCustomer(response.data.customer_id);
                }
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <>
                <Button className={this.props.hidden ? "d-none" : "mt-2"} color="primary" size="sm" onClick={this.toggle}><i className="fas fa-plus"></i> Customer</Button>
                <FormGroup>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} bsSize="lg">
                        <ModalHeader>
                            <div className="h3 mb-0">Add Customer</div>
                        </ModalHeader>
                        <ModalBody>
                            <Form>
                                <FormGroup row>
                                    <Label sm={4}>Name:</Label>
                                    <Col sm={8}>
                                        <Input type="text" size="sm" name="name" value={this.state.name} onChange={(event) => this.updateField("name", event)}/>
                                    </Col>
                                </FormGroup>
                            </Form>
                            <div className={this.props.goToCustomer == null ? "d-none" : "alert alert-info p-2"}>
                                More information is available to enter on the next screen
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.AddCustomer}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Add
                                Customer</Button>
                        </ModalFooter>
                    </Modal>
                </FormGroup>
            </>
        );
    };
}

export default (AddCustomer);
