import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import EditStaff from '../Staff/EditStaff';
import AddStaff from '../Staff/AddStaff';
import 'bootstrap/dist/css/bootstrap.css';
import moment from 'moment';
import 'moment-timezone';
import {getStaff} from "./StaffFunctions";

moment.tz.setDefault("America/Halifax");

class ListStaff extends React.Component {
    constructor(props) {
        super(props);

        this.loadStaff = this.loadStaff.bind(this);

        this.state = {
            loading: true,
            // default search settings
            product_id: null,
            // default search filter names
            product_name: "All",
            staff: [],
            permissions: [
                {code: 'FM', name: "Fleet Map"},
                {code: 'LO', name: "Loads"},
                {code: 'DI', name: "Dispatch"},
                {code: 'VE', name: "Vehicles"},
                {code: 'EQ', name: "Equipment"},
                {code: 'MA', name: "Maintenance"},
                {code: 'FU', name: "Fill-Ups"},
                {code: 'CU', name: "Customers"},
                {code: 'IN', name: "Invoices"},
                {code: 'DR', name: "Drivers"},
                {code: 'ST', name: "Staff"},
                {code: 'LC', name: "Locations"},
                {code: 'RE', name: "Reports"},
                {code: 'TR', name: "Trackers"},
                {code: 'SH', name: "Shares"},
            ]
        };
    }

    componentDidMount() {
        document.title = "Staff Manager | Greenhaven Transportation";
        var self = this;
        self.loadStaff();
    }

    loadStaff() {
        this.setState({loading: true});
        var self = this;
        getStaff(function (staff) {
            self.setState(
                {
                    staff: staff,
                    loading: false,
                });
        });
    }

    changeStatus = (product_id) => {
        if (product_id == null) {
            var product_name = "All"
        } else {
            var product_name = this.state.products[product_id].name
        }
        this.setState({
            product_id: product_id,
            product_name: product_name
        }, () => {
            this.updateStaff();
        });
    };


    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    Staff Manager
                    <div className="ml-auto">
                        <AddStaff loadStaff={this.loadStaff} permissions={this.state.permissions}/>
                    </div>
                </div>
                <div className="text-center alert alert-info">Please note that staff changes will take effect on their next login</div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(!this.state.loading ? "table table-hover " : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Permission(s)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {Object.keys(this.state.staff).map(function (staff_id, key) {
                                        var staff = this.state.staff[staff_id];
                                        return (
                                            <tr key={key}>
                                                <td><EditStaff staff={staff} loadStaff={this.loadStaff} permissions={this.state.permissions}/></td>
                                                <td>{staff.email}</td>
                                                <td><span
                                                    className={(staff.status == 1 || staff.status === true ? "badge badge-success" : "badge badge-danger")}>{(staff.status == 1 || staff.status === true ? "Active" : "Disabled")}</span>
                                                </td>
                                                <td>
                                                    {this.state.permissions.map(function (permission) {
                                                        if (staff.permissions != null && staff.permissions.indexOf(',' + permission.code + ',') != -1) {
                                                            return (
                                                                <span key={permission.code}>{permission.name}, </span>
                                                            )
                                                        }
                                                    }, this)}
                                                    {(staff.permissions == "ALL" ? "ALL" : "")}
                                                </td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (ListStaff);
