import React from "react";
import axios from 'axios';
import moment from 'moment';
import {API_ROOT} from '../../api-config';

export function getVehicles(response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/vehicles`, {withCredentials: true})
        .then(function (ajax_response) {
            var vehicles = {};
            vehicles.data = {};
            vehicles.data = ajax_response.data;
            if (Object.keys(vehicles.data).length > 0) {
                response(vehicles.data);
            }
        })
        .catch(function (error) {
            response({});
        });
}


export function addVehiclePhoto(response) {

}

export default getVehicles;