import React, {Component} from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    Row,
    Col,
    InputGroup,
    Input,
    InputGroupAddon,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import AddPhoto from '../Equipment/AddPhoto';
import AddFillup from '../Fillups/AddFillup';
import {MapLoad} from "../Equipment/Map";

import {getEquipmentTypes, getEquipmentTypesSub, getEquipmentManufacturers} from "../Equipment/EquipmentFunctions";
import money from "money-math";
import LoadStatus from "../Loads/LoadFunctions";
import getCustomers from "../Customers/CustomerFunctions";
import {getDrivers} from "../Drivers/DriverFunctions";
import getLocations from "../Locations/LocationFunctions";
import AddMaintenance from "../Maintenance/AddMaintenance";
import ShareLocation from "../Equipment/ShareLocation";


moment.tz.setDefault("America/Halifax");

class EquipmentPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            equipment_id: null,
            maint_id: null,
            maint_recs: {
                list: [],
                loading: true,
            },
            equipment: {
                loading: true,
                name: "",
                equipment_type_id: null,
                equipment_type_sub_id: null,
                province: '',
                plate: "",
                vin: "",
                acquired_odo: 0,
                status: 1,
                oos_details: "",
                equipment_manufacturer_id: null,
                model: "",
                year: null,
                colour: "",
                height_inches: null,
                width_inches: null,
                axles: null,
                weight_empty_kg: null,
                weight_gawr_kgs: null,
                weight_gvwr_kgs: null,
                length_inches: null,
                acquired_cost: null,
                acquired_from: "",
                date_acquired: null,
                cost_km: 0,
                cost_day: 0,
                photos: [],
                location: {
                    name: '',
                    speed: 0,
                    timestamp: moment().unix()
                }
            },
            province_list: ['NB', 'NS', 'PE', 'NL', 'ON', 'QC', 'SK', 'MB', 'AB', 'BC'],

            fillups: {
                list: [],
                loading: true,
            },
            fillup: {
                fillup_id: null,
                driver: "(select driver)",
                equipment_id: null,
                fill_date: null,
                odometer: "",
                litres: "",
                price_per_litre: "",
                pst_fuel: "",
                gst_fuel: "",
                total_tax_fuel: money.floatToAmount(0),
                total_fuel: money.floatToAmount(0),
                other: "",
                tax_other: "",
                total_other: money.floatToAmount(0),
                total: money.floatToAmount(0),
                location: "",
                chain: "(select chain)",
                driver_id: null,
            },
            expand_fillup: false,

            status_name: "(select)",
            equipment_statuses: {1: "In Service", 2: "Out of Service", 0: "Retired"},

            equipment_type_name: "(select)",
            equipment_types: {},

            equipment_type_sub_name: "(select)",
            equipment_types_sub: {},

            equipment_manufacturer_name: "(select)",
            equipment_manufacturers: {},

            drivers: {},
            locations: {},
            customers: {},

            safety_inspections: [],

            trips: {
                list: [],
                loading: true,
            },
            maint_records: {
                list: [],
                loading: true,
            },
            safety_inspections_modal: false,
            show_specs: false,
            show_acquired: false
        };
        this.equipmentChange = this.equipmentChange.bind(this);
        this.addPhotos = this.addPhotos.bind(this);
        this.refreshFillup = this.refreshFillup.bind(this);
    }

    componentDidMount() {
        document.title = "Equipment # " + this.props.match.params.equipment_id + " | Greenhaven Transportation";
        this.setState({equipment_id: this.props.match.params.equipment_id})
        var self = this;
        axios.defaults.withCredentials = true;
        getEquipmentTypes(function (equipment_types) {
            getEquipmentTypesSub(function (equipment_types_sub) {
                getEquipmentManufacturers(function (equipment_manufacturers) {
                    getCustomers(function (customers) {
                        getDrivers(function (drivers) {
                            getLocations(function (locations) {
                                axios.get(API_ROOT + '/equipment/' + self.props.match.params.equipment_id)
                                    .then(function (response) {
                                        response.data.loading = false;
                                        self.setState({
                                            customers: customers,
                                            drivers: drivers,
                                            locations: locations.locations,
                                            equipment_types: equipment_types,
                                            equipment_types_sub: equipment_types_sub,
                                            equipment_manufacturers: equipment_manufacturers,
                                            equipment: response.data,
                                            equipment_id: self.props.match.params.equipment_id,
                                            status_name: self.state.equipment_statuses[response.data.status],
                                            equipment_type_name: (response.data.equipment_type_id > 0 ? equipment_types[response.data.equipment_type_id].name : "(select)"),
                                            equipment_type_sub_name: (response.data.equipment_type_sub_id > 0 ? equipment_types_sub[response.data.equipment_type_sub_id].name : "(select)"),
                                            equipment_manufacturer_name: (response.data.equipment_manufacturer_id > 0 ? equipment_manufacturers[response.data.equipment_manufacturer_id].name : "(select)"),
                                        }, function () {
                                            self.refreshMaintenance()
                                        });
                                        self.refreshFillup();
                                    })
                                axios.get(API_ROOT + '/loads/?&equipment_id=' + self.props.match.params.equipment_id)
                                    .then(function (response) {
                                        var trips_sort = [];
                                        for (const trip_id of Object.keys(response.data)) {
                                            trips_sort.push(response.data[trip_id]);
                                        }
                                        var trips_sort = trips_sort.sort(function (a, b) {
                                            if (a.start > b.start) {
                                                return -1
                                            } else {
                                                return 1
                                            }
                                        });
                                        self.setState({
                                            trips: {loading: false, list: trips_sort},
                                        });
                                    })
                            });
                        });
                    });
                });
            });
        });
    }

    refreshFillup = (fillup_id) => {
        var self = this;
        axios.get(API_ROOT + '/fillups/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        fillups: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    loadCard() {
        var self = this;
        axios.get(API_ROOT + '/photos/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        photos: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    equipmentChange(event, name, value) {
        if (typeof event.target != "undefined" && event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        var state = this.state.equipment;
        state[name] = value;
        console.log(name);
        console.log(value)
        this.setState({
            equipment: state
        });
    }

    changeProvince = (province) => {
        var equipment = this.state.equipment;
        equipment.province = province;
        this.setState({
            equipment: equipment,
        });
    }

    changeEquipmentType = (equipment_type_id) => {
        var equipment_type_name = this.state.equipment_types[equipment_type_id].name;
        var equipment = this.state.equipment;
        equipment.equipment_type_id = equipment_type_id;
        this.setState({
            equipment: equipment,
            equipment_type_name: equipment_type_name,
            equipment_type_sub_name: "(select)"
        });
    }

    changeEquipmentTypeSub = (equipment_type_sub_id) => {
        var equipment_type_sub_name = this.state.equipment_types_sub[equipment_type_sub_id].name
        var equipment = this.state.equipment;
        equipment.equipment_type_sub_id = equipment_type_sub_id;
        this.setState({
            equipment: equipment,
            equipment_type_sub_name: equipment_type_sub_name
        });
    }

    changeEquipmentStatus = (status) => {
        var equipment = this.state.equipment;
        equipment.status = status;
        this.setState({
            equipment: equipment,
        });
        this.setState({
            status_name: this.state.equipment_statuses[status]
        });
    }

    changeEquipmentManufacturer = (equipment_manufacturer_id) => {
        var equipment_manufacturer_name = this.state.equipment_manufacturers[equipment_manufacturer_id].name
        var equipment = this.state.equipment;
        equipment.equipment_manufacturer_id = equipment_manufacturer_id;
        this.setState({
            equipment: equipment,
            equipment_manufacturer_name: equipment_manufacturer_name
        });
    }

    saveEquipmentDetails = async event => {
        if (event) {
            event.preventDefault();
        }
        var self = this;
        axios.defaults.withCredentials = true;
        axios.put(API_ROOT + '/equipment/' + this.props.match.params.equipment_id, {data: this.state.equipment})
            .then(function (response) {
                toast.success("Changes Saved", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    };

    goToOrder = (order_id) => {
        this.props.history.push("/order/" + order_id);
    };

    createBOL = async event => {
        this.props.history.push("/bol/create/" + this.state.equipment_id);
    };

    goToBOL = (bol_id) => {
        this.props.history.push("/bol/" + bol_id);
    };

    safety_inspections_toggle = () => {
        this.setState({
            safety_inspections_modal: !this.state.safety_inspections_modal
        });
    };

    addPhotos = (new_photo) => {
        var equipment = this.state.equipment;
        equipment['photos'].push({hash: new_photo, description: ''});
        this.setState({
            equipment: equipment
        })
    };

    viewFillup = (fillup) => {
        this.setState({
            fillup: fillup
        })
    }

    refreshMaintenance = () => {
        var self = this;
        axios.get(API_ROOT + '/maintenance/?equipment_id=' + this.props.match.params.equipment_id)
            .then(function (response) {
                self.setState(
                    {
                        maint_recs: {
                            list: response.data,
                            loading: false,
                        },
                        maint_id: null
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    viewMaintenance = (maint_id) => {
        this.setState({
            maint_id: maint_id
        })
    }


    render() {
        var total_est_cost = 0;
        var total_actual_cost = 0;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        <Link to="../vehicles">Vehicles</Link>&nbsp;/&nbsp; {this.state.equipment.name}
                    </div>
                    <div className={(this.state.equipment.customer_id == 0 ? "ml-auto" : "d-none")}>
                        <ShareLocation name={this.state.equipment.name} equipment_id={this.props.match.params.equipment_id}/>
                    </div>
                </div>
                <Row>
                    <Col lg={6}>
                        <Card className={(this.state.equipment.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <p className="lead bb">Details</p>
                                <form className="form-horizontal" onSubmit={this.saveEquipmentDetails}>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Number</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="name" value={this.state.equipment.name} required onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Type</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment_type_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_types).map((key, i) => {
                                                            if (typeof this.state.equipment_types != "undefined" && typeof this.state.equipment_types[key] != "undefined") {
                                                                return (<DropdownItem key={key}
                                                                                      onClick={() => this.changeEquipmentType(key)}>{this.state.equipment_types[key].name}</DropdownItem>)
                                                            }
                                                        }
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <i className="fas fa-angle-right ml-2 mr-2"></i>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.equipment_type_sub_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_types_sub).map((key, i) => {
                                                            if (typeof this.state.equipment_types_sub != "undefined" && typeof this.state.equipment_types_sub[key] != "undefined" && this.state.equipment.equipment_type_id == this.state.equipment_types_sub[key].equipment_type_id) {
                                                                return (
                                                                    <DropdownItem key={key}
                                                                                  onClick={() => this.changeEquipmentTypeSub(key)}>{this.state.equipment_types_sub[key].name}</DropdownItem>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className={this.state.equipment.equipment_type_id == 2 ? "mb-1" : "d-none"}>
                                        <label className="col-xl-3 col-form-label pt-0">Is a Reefer?</label>
                                        <div className="col-xl-9">

                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Make & Model</label>
                                        <div className="col-xl-9">
                                            <InputGroup size="sm">
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle size="sm" color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {this.state.equipment_manufacturer_name}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {Object.keys(this.state.equipment_manufacturers).map((key, i) => {
                                                                if (typeof this.state.equipment_manufacturers != "undefined" && typeof this.state.equipment_manufacturers[key] != "undefined") {
                                                                    return (<DropdownItem key={key}
                                                                                          onClick={() => this.changeEquipmentManufacturer(key)}>{this.state.equipment_manufacturers[key].name}</DropdownItem>)
                                                                }
                                                            }
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <i className="fas fa-angle-right mt-2 ml-2 mr-2"></i>
                                                <Input size="sm" type="text" name="model" value={this.state.equipment.model} onChange={this.equipmentChange}/>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Model Year</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="number" name="year" value={this.state.equipment.year} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">VIN</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="vin" value={this.state.equipment.vin} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Colour</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="colour" value={this.state.equipment.colour} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Plate #</label>
                                        <div className="col-xl-9">
                                            <InputGroup size="sm">
                                                <UncontrolledButtonDropdown>
                                                    <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                        {this.state.equipment.province}
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        {this.state.province_list.map((province, i) =>
                                                            <DropdownItem key={province} onClick={() => this.changeProvince(province)}>{province}</DropdownItem>
                                                        )}
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                <Input size="sm" type="text" name="plate" value={this.state.equipment.plate} onChange={this.equipmentChange}/>
                                            </InputGroup>
                                        </div>
                                    </Row>
                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Status</label>
                                        <div className="col-xl-9">
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="secondary" size="sm" caret style={{fontSize: '13px', width: 'auto'}}>
                                                    {this.state.status_name}
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {Object.keys(this.state.equipment_statuses).map((key, i) =>
                                                        <DropdownItem key={key} onClick={() => this.changeEquipmentStatus(key)}>{this.state.equipment_statuses[key]}</DropdownItem>
                                                    )}
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </div>
                                    </Row>
                                    <Row className={(this.state.equipment.status == 2 ? "mb-1" : "d-none")}>
                                        <label className="col-xl-3 col-form-label pt-0">OOS Reason</label>
                                        <div className="col-xl-9">
                                            <Input size="sm" type="text" name="oos_details" value={this.state.equipment.oos_details} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold" onClick={() => this.equipmentChange(false, 'show_specs', !this.state.equipment.show_specs)}>
                                                SPECIFICATIONS
                                                <div className={(this.state.equipment.show_specs ? "d-none" : "float-right")}><i className="fas fa-chevron-down"></i></div>
                                            </div>
                                        </div>
                                        <div className={(this.state.equipment.show_specs ? "" : "d-none")}>
                                            <Row className="mb-1">
                                                <label className="col-xl-4 col-form-label pt-0"># of Axles</label>
                                                <div className="col-xl-8">
                                                    <Input size="sm" type="text" name="axles" className="text-right" value={this.state.equipment.axles}
                                                           onChange={this.equipmentChange}/>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-4 col-form-label pt-0">Height <small>(exterior)</small></label>
                                                <div className="col-xl-8">
                                                    <InputGroup size="sm">
                                                        <Input type="text" name="height_inches" className="text-right" value={this.state.equipment.height_inches}
                                                               onChange={this.equipmentChange}/>
                                                        <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-4 col-form-label pt-0">Width <small>(exterior)</small></label>
                                                <div className="col-xl-8">
                                                    <InputGroup size="sm">
                                                        <Input size="sm" type="text" name="width_inches" className="text-right" value={this.state.equipment.width_inches}
                                                               onChange={this.equipmentChange}/>
                                                        <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-4 col-form-label pt-0">Length <small>(exterior)</small></label>
                                                <div className="col-xl-8">
                                                    <InputGroup size="sm">
                                                        <Input size="sm" type="text" name="length_inches" className="text-right" value={this.state.equipment.length_inches}
                                                               onChange={this.equipmentChange}/>
                                                        <InputGroupAddon addonType="append">inches</InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-4 col-form-label pt-0">Tare Weight <small>(excl. cargo)</small></label>
                                                <div className="col-xl-8">
                                                    <InputGroup size="sm">
                                                        <Input size="sm" type="text" name="weight_empty_kg" className="text-right" value={this.state.equipment.weight_empty_kg}
                                                               onChange={this.equipmentChange}/>
                                                        <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-4 col-form-label pt-0">GAWR</label>
                                                <div className="col-xl-8">
                                                    <InputGroup size="sm">
                                                        <Input size="sm" type="text" name="weight_gawr_kg" className="text-right" value={this.state.equipment.weight_gawr_kg}
                                                               onChange={this.equipmentChange}/>
                                                        <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                            <Row className="mb-0">
                                                <label className="col-xl-4 col-form-label pt-0">GVWR</label>
                                                <div className="col-xl-8">
                                                    <InputGroup size="sm">
                                                        <Input size="sm" type="text" name="weight_gvwr_kg" className="text-right" value={this.state.equipment.weight_gvwr_kg}
                                                               onChange={this.equipmentChange}/>
                                                        <InputGroupAddon size="sm" addonType="append">kg</InputGroupAddon>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold"
                                                 onClick={() => this.equipmentChange(false, 'show_acquired', !this.state.equipment.show_acquired)}>ACQUIRED
                                                <div className={(this.state.equipment.show_acquired ? "d-none" : "float-right")}><i className="fas fa-chevron-down"></i></div>
                                            </div>
                                        </div>
                                        <div className={(this.state.equipment.show_acquired ? "" : "d-none")}>
                                            <Row className="mb-1">
                                                <label className="col-xl-3 col-form-label pt-0">Purchase Date</label>
                                                <div className="col-xl-9">
                                                    <Input size="sm" type="date" name="date_acquired" value={this.state.equipment.date_acquired} onChange={this.equipmentChange}/>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-3 col-form-label pt-0">Acquired From</label>
                                                <div className="col-xl-9">
                                                    <Input size="sm" type="text" name="acquired_from" value={this.state.equipment.acquired_from} onChange={this.equipmentChange}/>
                                                </div>
                                            </Row>
                                            <Row className="mb-0">
                                                <label className="col-xl-3 col-form-label pt-0">Purchase Price</label>
                                                <div className="col-xl-9">
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                        <Input size="sm" type="text" name="acquired_odo" className="text-right" value={this.state.equipment.acquired_odo}
                                                               onChange={this.equipmentChange}/>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>

                                    <div className="border m-0 p-1 mb-1 bg-light rounded">
                                        <div className="row pb-1">
                                            <label className="col-xl-3 col-form-label pt-0"></label>
                                            <div className="col-xl-9 small text-bold">OPERATING COST (excl. fuel)</div>
                                        </div>
                                        <div>
                                            <Row className="mb-1">
                                                <label className="col-xl-3 col-form-label pt-0">Per KM</label>
                                                <div className="col-xl-9">
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                        <Input size="sm" type="text" name="cost_km" className="text-right" value={this.state.equipment.cost_km}
                                                               onChange={this.equipmentChange}/>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                            <Row className="mb-1">
                                                <label className="col-xl-3 col-form-label pt-0">Per Day</label>
                                                <div className="col-xl-9">
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon size="sm" addonType="append">$</InputGroupAddon>
                                                        <Input size="sm" type="text" name="cost_day" className="text-right" value={this.state.equipment.cost_day}
                                                               onChange={this.equipmentChange}/>
                                                    </InputGroup>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>

                                    <Row className="mb-1">
                                        <label className="col-xl-3 col-form-label pt-0">Notes</label>
                                        <div className="col-xl-9">
                                            <Input type="textarea" style={{minHeight: "50px"}} name="notes" value={this.state.equipment.notes} onChange={this.equipmentChange}/>
                                        </div>
                                    </Row>
                                    <Row className="">
                                        <div className="offset-xl-3 col-xl-9">
                                            <button className="btn btn-primary" type="submit">Save Changes</button>
                                        </div>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card className="card card-default d-none">
                            <CardBody>
                                <p className="lead bb">Current Status</p>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Speed</label>
                                    <div className="col-xl-8">
                                        <div className="text-bold">14km/hr <small>17 minutes ago</small></div>
                                    </div>
                                </Row>
                                <Row>
                                    <label className="pt-0 col-xl-4 col-form-label">Location</label>
                                    <div className="col-xl-8">

                                    </div>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <div className="lead bb">
                                    {this.state.equipment.photos.length} Photo{(this.state.equipment.photos.length > 1 ? 's' : '')}
                                    <div className="float-right">
                                        <AddPhoto equipment_id={this.props.match.params.equipment_id} addPhotos={this.addPhotos}/>
                                    </div>
                                </div>
                                <div>
                                    {this.state.equipment.photos.map(function (id, key) {
                                        return (
                                            <img src={"../img/photos/" + id['hash'] + ".jpg"} title={id['description']} className="img-fluid rounded mx-auto d-block"
                                                 style={{maxHeight: '250px'}}/>
                                        )
                                    }, this)}
                                </div>
                            </CardBody>
                        </Card>
                        <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                            <CardBody>
                                <div className="lead bb">
                                    Current Location
                                    <div className="float-right">
                                        <Link to={"../map/" + this.state.equipment_id}>
                                            <div className="btn btn-xs btn-primary mb-2">View on Fleet Map</div>
                                        </Link>
                                    </div>
                                </div>
                                <div>
                                    As
                                    of {moment.duration(moment().unix() - this.state.equipment.location.timestamp, 'seconds').humanize()} ago: {(this.state.equipment.location.type == "ping" ? "Located at " : "Scheduled to be ")} {this.state.equipment.location.name}, {(this.state.equipment.location.speed == null ? "0 km/hr" : "moving " + this.state.equipment.location.speed + " km/hr")}
                                </div>
                                <div style={{height: "250px"}}>
                                    <MapLoad point={this.state.equipment.location}/>
                                </div>
                            </CardBody>
                        </Card>
                        <Card className="card card-default">
                            <CardBody>
                                <p className="lead bb">{this.state.fillups.list.length} Fill-Ups
                                    <div className="float-right">
                                        <AddFillup equipment_id={this.props.match.params.equipment_id} refreshFillup={this.refreshFillup} viewFillup={this.state.fillup}/>
                                    </div></p>
                                <table
                                    className={(this.state.fillups.list.length > 0 && !this.state.fillups.loading ? "table table-sm table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left">Date</th>
                                        <th>ODO</th>
                                        <th>Litres</th>
                                        <th>Per Litre</th>
                                        <th>Total</th>
                                        <th>MPG</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.fillups.list.map(function (item, key) {
                                        return (
                                            <tr key={key} onClick={this.viewFillup.bind(this, item)} className={(key > 8 && !this.state.expand_fillup ? "d-none" : "")}>
                                                <td className="text-left">{moment(item.fill_date).format("MMM. D/YY HH:mm")}</td>
                                                <td>{(item.odometer == null ? "" : item.odometer.toLocaleString())}</td>
                                                <td>{item.fuel_litres}</td>
                                                <td>${money.format("USD", money.floatToAmount(item.fuel_ppl))}</td>
                                                <td>${money.format("USD", money.floatToAmount(item.fuel_total_cost + item.def_total_cost))}</td>
                                                <td>{item.mpg}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                                <div className="text-center">
                                    <div className={(this.state.expand_fillup ? "d-none" : "btn btn-primary mt-1")} onClick={() => this.setState({expand_fillup: true})}><i
                                        className="fa-solid fa-caret-down"></i> Show All
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Card className={(this.state.trips.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">{this.state.trips.list.length} Trips</p>
                        <div className="table-responsive">
                            <table className={(this.state.trips.list.length > 0 && !this.state.trips.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">#</th>
                                    <th className="d-none d-md-table-cell">Date</th>
                                    <th className="d-none d-md-table-cell">Origin</th>
                                    <th className="d-none d-lg-table-cell">Destination</th>
                                    <th className="d-none d-lg-table-cell">Customer</th>
                                    <th className="d-none d-lg-table-cell">Driver</th>
                                    <th className="d-none d-lg-table-cell">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.trips.list.map(function (item, key) {
                                    return (
                                        <tr key={key} onClick={this.goToLoad.bind(this, item.load_id)}>
                                            <td className="d-none d-lg-table-cell">{item.load_id}</td>
                                            <td className="d-none d-md-table-cell">{moment(item.start).format("MMM. D/YY HH:mm")}</td>
                                            <td className="d-none d-md-table-cell">{(item.origin != null && typeof this.state.locations[item.origin] != "undefined" ? this.state.locations[item.origin].name : "")}</td>
                                            <td className="d-none d-md-table-cell">{(item.destination != null && typeof this.state.locations[item.destination] != "undefined" ? this.state.locations[item.destination].name : "")}</td>
                                            <td className="d-none d-lg-table-cell">{(item.customer_id != null && typeof this.state.customers[item.customer_id] != "undefined" ? this.state.customers[item.customer_id].name : "")}</td>
                                            <td className="d-none d-lg-table-cell">{(item.driver_id != null && typeof this.state.drivers[item.driver_id] != "undefined" ? this.state.drivers[item.driver_id].first_name + " " + this.state.drivers[item.driver_id].last_name : "")}</td>
                                            <td><LoadStatus status={item.status}/></td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <Card style={(this.state.equipment.customer_id != 0 ? {display: "none"} : {})}
                      className={(this.state.maint_recs.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <p className="lead bb">{this.state.maint_recs.list.length} Maintenance Records
                            <div className="float-right">
                                <AddMaintenance maint_id={this.state.maint_id} equipment_id={this.props.match.params.equipment_id} refreshMaintenance={this.refreshMaintenance}/>
                            </div>
                        </p>
                        <div className="table-responsive">
                            <table
                                className={(this.state.maint_recs.list.length > 0 && !this.state.maint_recs.loading ? "table table-sm table-hover table-pointer" : "d-none")}>
                                <thead>
                                <tr>
                                    <th className="d-none d-lg-table-cell">#</th>
                                    <th className="d-none d-md-table-cell">Date Ent.</th>
                                    <th className="d-none d-md-table-cell">Category</th>
                                    <th className="d-none d-md-table-cell">Description</th>
                                    <th className="d-none d-md-table-cell">Urgency</th>
                                    <th className="d-none d-lg-table-cell">Vendor</th>
                                    <th className="text-right d-none d-lg-table-cell">Est. Cost</th>
                                    <th className="text-right d-none d-lg-table-cell">Act. Cost</th>
                                    <th className="d-none d-lg-table-cell">Status</th>
                                    <th className="d-none d-lg-table-cell">Reminder</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.maint_recs.list.map(function (item, key) {
                                    if (item.cost_est > 0) {
                                        total_est_cost = total_est_cost + item.cost_est;
                                    }
                                    if (item.cost_actual > 0) {
                                        total_actual_cost = total_actual_cost + item.cost_actual;
                                    }
                                    return (
                                        <tr key={key} onClick={this.viewMaintenance.bind(this, item.maint_id)}>
                                            <td className="d-none d-lg-table-cell">{item.maint_id}</td>
                                            <td className="d-none d-md-table-cell">{moment(item.date_enter).format("MMM. D/YY HH:mm")}</td>
                                            <td className="d-none d-lg-table-cell">{item.category}</td>
                                            <td className="d-none d-lg-table-cell">{item.description}</td>
                                            <td className="d-none d-md-table-cell">{(item.urgency == 0 ? "Low" : (item.urgency == 1 ? "Medium" : "High (OOS)"))}</td>
                                            <td className="d-none d-lg-table-cell">{item.vendor_name}</td>
                                            <td className="text-right d-none d-lg-table-cell">{(item.cost_est > 0 ? "$" + item.cost_est : "")}</td>
                                            <td className="text-right d-none d-lg-table-cell">{(item.cost_actual > 0 ? "$" + item.cost_actual : "")}</td>
                                            <td className="text-left">
                                                <div className={(item.status == 0 ? "badge badge-dark" : "d-none")}>Pending Auth.</div>
                                                <div className={(item.status == 1 ? "badge badge-danger" : "d-none")}>Declined</div>
                                                <div className={(item.status == 2 ? "badge badge-info" : "d-none")}>Approved</div>
                                                <div className={(item.status == 3 ? "badge badge-purple" : "d-none")}>Completed, Not Billed</div>
                                                <div className={(item.status == 4 ? "badge badge-green" : "d-none")}>Completed</div>
                                            </td>
                                            <td className="d-none d-lg-table-cell">{(item.reminder_date != null ? "Yes" : "No")}</td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                                <tfoot>
                                <tr>
                                    <th className="text-right" colspan="6">Total:</th>
                                    <th className="text-right d-none d-lg-table-cell">${total_est_cost}</th>
                                    <th className="text-right d-none d-lg-table-cell">${total_actual_cost}</th>
                                    <th colSpan="2"></th>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </CardBody>
                </Card>
                <ToastContainer/>
            </ContentWrapper>
        );
    }

}

export default EquipmentPage;
