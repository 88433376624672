import React from "react";
import axios from 'axios';
import {API_ROOT} from '../../api-config';

export async function getLocations(response) {
    var fetch_data = true;
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        await axios.get(`${API_ROOT}/locations/`, {withCredentials: true})
            .then(async function (ajax_response) {
                response({
                    locations: ajax_response.data.locations,
                    locations_sort: ajax_response.data.locations_sort
                });
            })
            .catch(async function (error) {
                response({});
            });
    }
}

export default getLocations;