import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    DropdownToggle, DropdownItem, UncontrolledButtonDropdown
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import moment from 'moment';
import 'moment-timezone';
import getLocations from "../Locations/LocationFunctions";
import {getDrivers} from '../Drivers/DriverFunctions';
import {getEquipment} from "../Equipment/EquipmentFunctions";
import {MapLoad} from "./Map";
import LocationDisplay from "../Locations/LocationDisplay";

moment.tz.setDefault("America/Halifax");

class LoggedTrip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modal_add: false,
            processing: false,
            loading: true,
            equipment_id: null,
            driver_id: null,
            merge_load_ids: [],
            related: [],
            locations: {},
            drivers: {},
            equipment: {},
            segments: [],
            pings: [],
            points: [],
            loaded_log_id: [],
            force_new_segment: [],
            skip_new_segment: [],
            hover_ping: false,
            hover_segment: false,
            zoom_to: false,
            linestring: {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "MultiLineString",
                    "coordinates": []
                }
            },
            referenceline: {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "MultiLineString",
                    "coordinates": []
                }
            },
            location_modal: false,
            location_id: false,
        };
        this.toggle_location = this.toggle_location.bind(this);
        this.update_locations = this.update_locations.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (typeof this.props.log_id != "undefined" && prevProps.log_id !== this.props.log_id && this.props.log_id != null && this.state.modal == false) {
            this.toggle(this.props.log_id);
        }
    }

    getLog = async (log_id) => {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/drivers/log/${log_id}`, {withCredentials: true})
            .then(function (response) {
                var loaded_log_id = self.state.loaded_log_id;
                loaded_log_id.push(parseInt(log_id));
                response.data.pings.forEach((ping) => {
                    ping.driver_id = response.data.driver_id;
                });
                var merge_load_ids = (response.data.merge_load_ids == null ? self.state.merge_load_ids : self.state.merge_load_ids.concat(JSON.parse("[" + response.data.merge_load_ids + "]")));
                merge_load_ids = [...new Set(merge_load_ids)]; // de-dupe
                self.setState({
                        equipment_id: (self.state.equipment_id == null ? response.data.equipment_id : self.state.equipment_id),
                    merge_load_ids: merge_load_ids,
                        loaded_log_id: loaded_log_id,
                        driver_id: response.data.driver_id,
                        pings: [].concat(self.state.pings, response.data.pings),
                        related: (self.state.related.length == 0 ? response.data.related : self.state.related),
                        modal_add: false,
                        hover_ping: false,
                        hover_segment: false
                    }, function () {
                        self.splitSegments()
                    }
                );
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    toggle = async (log_id) => {
        if (!this.state.modal && log_id != false) {
            var locations = this.props.locations;
            if (typeof this.props.locations == "undefined") {
                await getLocations(async function (temp) {
                    locations = temp;
                });
            }
            var drivers = this.props.drivers;
            if (typeof drivers == "undefined") {
                await getDrivers(async function (temp) {
                    drivers = temp;
                });
            }
            var equipment = this.props.equipment;
            if (typeof equipment == "undefined") {
                await getEquipment(0, async function (temp) {
                    equipment = temp;
                });
            }
            this.setState({
                locations: locations,
                drivers: drivers,
                equipment: equipment,
                loading: false
            }, () => this.getLog(log_id));
        } else {
            this.setState({
                merge_load_ids: [],
                equipment_id: null,
                loaded_log_id: [],
                related: [],
                segments: [],
                pings: [],
                points: [],
            });
            if (typeof this.props.modal_closed_callback == "function") {
                this.props.modal_closed_callback(null);
            }
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    toggle_add = async () => {
        this.setState({
            modal_add: !this.state.modal_add,
        });
    }

    toggle_location = (location_id) => {
        var location_modal = (this.state.location_modal == true ? false : true);
        if (isNaN(location_id)) {
            location_id = false;
        }
        this.setState({
            location_modal: location_modal,
            location_id: location_id
        });
    };

    update_locations() {
        var self = this;
        getLocations(async function (temp) {
            self.setState({
                locations: temp.locations,
            });
        });
    }

    calcCrow(lat1, lon1, lat2, lon2) {
        var R = 6371; // km
        var dLat = this.toRad(lat2 - lat1);
        var dLon = this.toRad(lon2 - lon1);
        var lat1 = this.toRad(lat1);
        var lat2 = this.toRad(lat2);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d;
    }

    toRad(Value) {
        return Value * Math.PI / 180;
    }

    roundDown(num) {
        const factor = Math.pow(10, 2);
        return Math.floor(num * factor) / factor;
    }

    roundUp(num) {
        const factor = Math.pow(10, 2);
        return Math.ceil(num * factor) / factor;
    }


    splitSegments = async event => {
        var linestring = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [[]]
            }
        };
        var referenceline = {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "MultiLineString",
                "coordinates": [[]]
            }
        };
        var points = [];
        var segments = [];
        var last_point = {};
        var segment_num = -1;
        var pings = this.state.pings;
        pings.sort((a, b) => parseFloat(a.timeStamp) - parseFloat(b.timeStamp));
        for (const ping of pings) {
            points.push({
                lat: ping.latitude,
                lon: ping.longitude,
                name: ping.name,
                address: ping.address,
                type: "marker",
                hidden: true,
                timeStamp: ping.timeStamp
            })

            // add new segment. if now moving but was stopped
            if (segment_num == -1) {
                segment_num++;
                segments.push({
                    origin_id: ping.location_id,
                    origin_details: ping,
                    destination_id: null,
                    destination_details: null,
                    driver_id: ping.driver_id,
                    start: moment.unix(ping.timeStamp),
                    distance_miles: 0,
                    duration_hrs: 0,
                    wait_hrs: 0,
                    billable: 1,
                    pings: [ping],
                    show_pings: (typeof this.state.segments[segment_num] == "undefined" ? false : this.state.segments[segment_num].show_pings),
                    checked: (typeof this.state.segments[segment_num] == "undefined" ? true : this.state.segments[segment_num].checked),
                    breaking_ping: ping.timeStamp
                })
            } else if (
                // detect if they are leaving, starting a new trip
                !this.state.skip_new_segment.includes(ping.timeStamp) && (
                    last_point.driver_id != ping.driver_id ||
                    this.state.force_new_segment.includes(last_point.timeStamp) ||
                    (
                        (typeof this.state.locations[last_point.location_id] == "undefined" || this.state.locations[last_point.location_id].is_stop != 0) &&
                        (ping.distance_travelled > 0 || ping.speed_km > 0) &&
                        (
                            (last_point.location_id != null && last_point.location_id != ping.location_id && last_point.location_id != segments[segment_num].origin_id) || // if they were stopped at a known location, but not anymore. Also, don't set if the origin and destination would be the same (e.g. first segment)
                            (last_point.location_id == null && ping.location_id == null && (ping.speed_limit == null || ping.speed_limit <= 90)) // if they were not, and are not currently at, a known location - e.g. a new location
                        ) &&
                        (last_point.speed_km == 0 || last_point.speed_km == null) &&
                        this.calcCrow(segments[segment_num].origin_details.latitude, segments[segment_num].origin_details.longitude, last_point.latitude, last_point.longitude) > 1
                    )
                )
            ) {
                segments[segment_num].destination_id = last_point.location_id;
                segments[segment_num].destination_details = last_point;
                if (this.state.hover_segment === false || this.state.hover_segment === segments.length) {
                    linestring.geometry.coordinates[0].push([last_point.longitude, last_point.latitude]);
                }
                if (ping.speed_km > 0 || ping.distance_travelled > 0) {
                    segments[segment_num].duration_hrs += (ping.timeStamp - last_point.timeStamp) / 60 / 60;
                } else {
                    segments[segment_num].wait_hrs += (ping.timeStamp - last_point.timeStamp) / 60 / 60;
                }
                segment_num++;
                segments.push({
                    origin_id: last_point.location_id,
                    origin_details: last_point,
                    destination_id: null,
                    destination_details: null,
                    driver_id: ping.driver_id,
                    start: moment.unix(last_point.timeStamp),
                    distance_miles: (ping.distance_travelled == null ? 0 : ping.distance_travelled),
                    duration_hrs: this.roundDown((ping.timeStamp - last_point.timeStamp) / 60 / 60),
                    wait_hrs: 0,
                    billable: 1,
                    pings: [last_point, ping],
                    show_pings: (typeof this.state.segments[segment_num] == "undefined" ? false : this.state.segments[segment_num].show_pings),
                    checked: (typeof this.state.segments[segment_num] == "undefined" ? true : this.state.segments[segment_num].checked),
                    breaking_ping: ping.timeStamp
                });
            } else if (
                // detect if they are arriving at a location, ending a new trip
                !this.state.skip_new_segment.includes(ping.timeStamp) &&  //true
                (
                    (typeof this.state.locations[ping.location_id] == "undefined" || this.state.locations[ping.location_id].is_stop != 0) &&
                    (
                        (
                            (ping.distance_travelled > 0 || last_point.speed_km > 0) && ping.speed_km == 0 && // they were moving but are now stopped, and
                            last_point.location_id != ping.location_id && ping.location_id != null  // if they were not at a known location but they are now
                        ) || (
                            //last_point.location_id == null && // at an unknown location but shut down
                            ping.is_ignition == 2 &&
                            last_point.speed_km == 0 &&
                            ping.speed_km == 0
                        )
                    )
                    && this.calcCrow(segments[segment_num].origin_details.latitude, segments[segment_num].origin_details.longitude, ping.latitude, ping.longitude) > 1 // and it's over 1km from the start point.
                )
            ) {
                segments[segment_num].destination_id = ping.location_id;
                segments[segment_num].destination_details = ping;
                segments[segment_num].pings.push(ping);
                if (this.state.hover_segment === false || this.state.hover_segment === segment_num) {
                    linestring.geometry.coordinates[0].push([ping.longitude, ping.latitude]);
                }
                if (ping.speed_km > 0 || ping.distance_travelled > 0) {
                    segments[segment_num].duration_hrs += (ping.timeStamp - last_point.timeStamp) / 60 / 60;
                } else {
                    segments[segment_num].wait_hrs += (ping.timeStamp - last_point.timeStamp) / 60 / 60;
                }
                segment_num++;
                segments.push({
                    origin_id: ping.location_id,
                    origin_details: ping,
                    destination_id: null,
                    destination_details: null,
                    driver_id: ping.driver_id,
                    start: moment.unix(ping.timeStamp),
                    distance_miles: 0,
                    duration_hrs: 0,
                    wait_hrs: 0,
                    billable: 1,
                    pings: [ping],
                    show_pings: (typeof this.state.segments[segment_num] == "undefined" ? false : this.state.segments[segment_num].show_pings),
                    checked: (typeof this.state.segments[segment_num] == "undefined" ? true : this.state.segments[segment_num].checked),
                    breaking_ping: ping.timeStamp
                })
            } else {
                segments[segment_num].pings.push(ping);
                segments[segment_num].distance_miles += (ping.distance_travelled == null ? 0 : ping.distance_travelled);
                if (ping.speed_km > 0 || ping.distance_travelled > 0) {
                    segments[segment_num].duration_hrs += (ping.timeStamp - last_point.timeStamp) / 60 / 60;
                } else {
                    segments[segment_num].wait_hrs += (ping.timeStamp - last_point.timeStamp) / 60 / 60;
                }
            }

            if (this.state.hover_segment === false || this.state.hover_segment === segment_num) {
                linestring.geometry.coordinates[0].push([ping.longitude, ping.latitude]);
            }
            referenceline.geometry.coordinates[0].push([ping.longitude, ping.latitude]);

            last_point = ping;
        }
        if (segment_num >= 0) {
            segments[segment_num].destination_id = last_point.location_id;
            segments[segment_num].destination_details = last_point;
        }
        // if the last segment has the same start and end point, and there are more than 1 segments, combine it with the 2nd last segment.
        if (segment_num >= 1 && segments[segment_num].destination_id != null && segments[segment_num].destination_id == segments[segment_num].origin_id && segments[segment_num - 1].destination_id == segments[segment_num].origin_id) {
            segments[segment_num - 1].pings.push(segments[segment_num].pings[0]);
            segments[segment_num - 1].distance_miles += segments[segment_num].distance_miles
            segments[segment_num - 1].duration_hrs += segments[segment_num].duration_hrs;
            segments[segment_num - 1].wait_hrs += segments[segment_num].wait_hrs;
            delete segments[segment_num];
        }

        // check segments and adjust driving time as needed
        var self = this;
        segments.map(async function (segment, segment_num) {
            if (typeof segments[segment_num] != "undefined") {
                var total_time = ((segments[segment_num].pings[segments[segment_num].pings.length - 1].timeStamp - segment.pings[0].timeStamp) / 60 / 60)
                var logged_time = segment.duration_hrs + segment.wait_hrs
                var correction = logged_time - total_time;
                if (correction > 0) {
                    segments[segment_num].duration_hrs = segments[segment_num].duration_hrs - self.roundUp(correction);
                }
            }
        });
        this.setState({
            points: points,
            segments: segments,
            linestring: linestring,
            referenceline: referenceline
        });
    }

    createLoad = async event => {
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/loads/segment', this.state)
            .then(function (response) {
                self.toggle(false);
                self.setState({
                    processing: false
                });
                Swal.fire({
                    title: 'Load ' + response.data.load_id + ' Created',
                    text: "Would you like to go to it now?",
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning'
                }).then((result) => {
                    if (result.value) {
                        self.props.openLoad(response.data.load_id);
                    } else {
                        self.props.update_load();
                    }
                });
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    };

    mergeLoad = (mode, merge_load_id) => {
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/loads/merge/' + merge_load_id, {
            clear_segments: (mode == "overwrite" ? "TRUE" : "FALSE"),
            vehicle_id: this.state.equipment_id,
            segments: this.state.segments
        })
            .then(function (response) {
                self.setState({
                    processing: false
                });
                Swal.fire({
                    title: 'Load ' + response.data.load_id + ' Updated',
                    text: "Would you like to go to it now?",
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    type: 'warning'
                }).then((result) => {
                    if (result.value) {
                        self.props.openLoad(merge_load_id);
                    } else {
                        self.toggle(false);
                        self.props.update_load();
                    }
                });
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                }
            });
    };

    togglePings(segment_num) {
        var segments = this.state.segments;
        segments[segment_num].show_pings = !segments[segment_num].show_pings;
        this.setState({
            segments: segments
        });
    }

    hoverPing(timeStamp) {
        this.setState({
            hover_ping: timeStamp,
        });
    }

    hoverSegment(segment_num) {
        if (segment_num !== this.state.hover_segment) {
            this.setState({
                    hover_segment: segment_num,
                }, function () {
                    this.splitSegments()
                }
            );
        }
    }

    toggleSegment(segment_id) {
        var segments = this.state.segments;
        segments[segment_id].checked = !segments[segment_id].checked;
        this.setState({
                segments: segments,
            }
        );
    }

    updateSegmentName(segment_id, end, event) {
        var segments = this.state.segments;
        segments[segment_id][end].description = event.target.value;
        this.setState({
                segments: segments,
            }
        );
    }

    clickPing(ping) {
        this.setState({
            zoom_to: {latitude: ping.latitude, longitude: ping.longitude}
        });
    }

    forceSegment(timeStamp, event) {
        event.preventDefault();
        event.stopPropagation();
        var force_new_segment = this.state.force_new_segment;
        force_new_segment.push(timeStamp);
        this.setState({
                force_new_segment: force_new_segment
            }, function () {
                this.splitSegments()
            }
        );
    }

    compressSegment(timeStamp, event) {
        //event.preventDefault();
        // event.stopPropagation();
        var skip_new_segment = this.state.skip_new_segment;
        skip_new_segment.push(timeStamp);
        this.setState({
                skip_new_segment: skip_new_segment
            }, function () {
                this.splitSegments()
            }
        );
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle} size="xl" fullscreen={true}>
                    <ModalHeader>
                        <div
                            className="h3 mb-0">ELD: {(this.state.driver_id == null || typeof this.state.drivers[this.state.driver_id] == "undefined" ? "" : this.state.drivers[this.state.driver_id].first_name + " " + this.state.drivers[this.state.driver_id].last_name)}, {(typeof this.state.equipment[this.state.equipment_id] == "undefined" ? "" : "Truck " + this.state.equipment[this.state.equipment_id].name)}
                        </div>
                    </ModalHeader>
                    <div className={(this.state.loading ? "whirl traditional" : "")}>
                        <div style={{height: "calc(100vh*.35)"}}>
                            <MapLoad points={this.state.points} travel_line={this.state.linestring} reference_line={this.state.referenceline} hover_ping={this.state.hover_ping}
                                     zoom_to={this.state.zoom_to}/>
                        </div>
                        <Form>
                            <div style={{maxHeight: "calc(100vh - 500px)", overflowY: "auto"}}>
                                <table className="table table-sm table-hover">
                                    <thead>
                                    <tr className="thead-light">
                                        <th colspan="2">
                                            <div className={(this.state.related.length < 2 ? "d-none" : "btn btn-xs btn-info")} onClick={() => this.toggle_add()}>Add More</div>
                                        </th>
                                        <th>Start</th>
                                        <th>Origin</th>
                                        <th>Destination</th>
                                        <th>Driving Hrs</th>
                                        <th>Wait Hrs</th>
                                        <th>Dist. KM (rough)</th>
                                        <th>Driver</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.segments.map(function (segment, segment_num) {
                                        return (
                                            <>
                                                <tr onMouseOver={() => this.hoverSegment(segment_num)} onMouseOut={() => this.hoverSegment(false)}>
                                                    <td className="border-bottom-0 pr-0 pl-3">
                                                        <input type="checkbox" value="1" checked={segment.checked} onChange={() => this.toggleSegment(segment_num)}/>
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        <div
                                                            className={(segment_num == 0 || this.state.segments[segment_num - 1].driver_id != segment.driver_id ? "d-none" : "btn btn-xs p-0 btn-light")}
                                                            title="Combine with Above Trip"
                                                            onClick={(event) => this.compressSegment(segment.breaking_ping, event)}>
                                                            <i className="fa-solid fa-arrow-up-from-bracket"></i>
                                                        </div>
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        {segment.start.format("YYYY-MM-DD HH:mm:ss")}
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        <span className={(segment.origin_id == null ? "" : "d-none")}>
                                                            <input type="text" className="form-control form-control-sm" value={segment.origin_details.description}
                                                                   onChange={(event) => this.updateSegmentName(segment_num, 'origin_details', event)}
                                                            />
                                                        </span>
                                                        <span className={(segment.origin_id == null ? "d-none" : "")}>
                                                            <span className="btn btn-text m-0 p-0" onClick={() => this.toggle_location(segment.origin_id)}>
                                                                <i className={(typeof this.state.locations[segment.origin_id] != "undefined" && this.state.locations[segment.origin_id].is_stop == 0 ? "far fa-hourglass text-success mr-1" : "d-none")}
                                                                   title="Location is not a stop or destination"></i>
                                                                <i className={(typeof this.state.locations[segment.origin_id] != "undefined" && this.state.locations[segment.origin_id].is_stop == 1 ? "fas fa-hourglass-half text-warning mr-1" : "d-none")}
                                                                   title="Location is a stop"></i>
                                                                <i className={(typeof this.state.locations[segment.origin_id] != "undefined" && this.state.locations[segment.origin_id].is_stop == 2 ? "fas fa-hourglass-end text-danger mr-1" : "d-none")}
                                                                   title="Location is a destination"></i>
                                                                {(segment.origin_id == null || typeof this.state.locations[segment.origin_id] == "undefined" ? segment.origin_details.description : this.state.locations[segment.origin_id].name)}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        <span className={(segment.destination_id == null ? "input-group" : "d-none")}>
                                                            <i className="fas fa-hourglass-half text-warning mt-1 mr-1 ml-3" title="Location is not a stop or destination"></i>
                                                            <input type="text" className="form-control form-control-sm" value={segment.destination_details.description}
                                                                   onChange={(event) => this.updateSegmentName(segment_num, 'destination_details', event)}
                                                            />
                                                        </span>
                                                        <span className={(segment.destination_id == null ? "d-none" : "")}>
                                                             <span className="btn btn-text m-0 p-0" onClick={() => this.toggle_location(segment.destination_id)}>
                                                                <i className={(typeof this.state.locations[segment.destination_id] != "undefined" && this.state.locations[segment.destination_id].is_stop == 0 ? "far fa-hourglass text-success mr-1" : "d-none")}
                                                                   title="Location is not a stop or destination"></i>
                                                                <i className={(typeof this.state.locations[segment.destination_id] != "undefined" && this.state.locations[segment.destination_id].is_stop == 1 ? "fas fa-hourglass-half text-warning mr-1" : "d-none")}
                                                                   title="Location is a stop"></i>
                                                                <i className={(typeof this.state.locations[segment.destination_id] != "undefined" && this.state.locations[segment.destination_id].is_stop == 2 ? "fas fa-hourglass-end text-danger mr-1" : "d-none")}
                                                                   title="Location is a destination"></i>
                                                                 {(segment.destination_id == null || typeof this.state.locations[segment.destination_id] == "undefined" ? segment.destination_details.description : this.state.locations[segment.destination_id].name)}
                                                            </span>
                                                        </span>
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        {segment.duration_hrs.toFixed(2)}
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        {segment.wait_hrs.toFixed(2)}
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        {segment.distance_miles.toFixed(2)}
                                                    </td>
                                                    <td className="border-bottom-0 pb-0">
                                                        {this.state.drivers[segment.driver_id].first_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colspan="9" className="border-top-0 pt-0 pl-4" onMouseOver={() => this.hoverSegment(segment_num)}>
                                                        <div className={(segment.show_pings == true ? "d-none" : "text-center")}>
                                                            <div className="btn btn-xs pt-0 pb-0 btn-light w-100" onClick={() => this.togglePings(segment_num)}>
                                                                <small>
                                                                    <i className="fas fa-chevron-down mr-1"></i> Show {segment.pings.length} Pings
                                                                </small>
                                                            </div>
                                                        </div>
                                                        <table className={(segment.show_pings == true ? "table table-sm small table-hover mb-0" : "d-none")}>
                                                            <thead className="thead-dark">
                                                            <tr>
                                                                <th>Split</th>
                                                                <th className="p-0">Date / Time</th>
                                                                <th className="p-0">Location</th>
                                                                <th className="p-0">Ignition</th>
                                                                <th className="p-0">Speed</th>
                                                                <th className="p-0">Limit</th>
                                                                <th className="p-0">Dir.</th>
                                                                <th className="p-0">Dist. Travelled</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {segment.pings.map(function (ping, ping_num) {
                                                                var rotate = false;
                                                                if (ping.direction == "n") {
                                                                    rotate = 315;
                                                                } else if (ping.direction == "ne") {
                                                                    rotate = 0;
                                                                } else if (ping.direction == "e") {
                                                                    rotate = 45;
                                                                } else if (ping.direction == "se") {
                                                                    rotate = 95;
                                                                } else if (ping.direction == "s") {
                                                                    rotate = 135;
                                                                } else if (ping.direction == "sw") {
                                                                    rotate = 180;
                                                                } else if (ping.direction == "w") {
                                                                    rotate = 225;
                                                                } else if (ping.direction == "nw") {
                                                                    rotate = 270;
                                                                }
                                                                return (
                                                                    <tr onClick={() => this.clickPing(ping)} onMouseOver={() => this.hoverPing(ping.timeStamp)}
                                                                        onMouseOut={() => this.hoverPing(false)}>
                                                                        <td>
                                                                            <div className="btn btn-xm p-0 btn-light" title="Start as New Trip"
                                                                                 onClick={(event) => this.forceSegment(ping.timeStamp, event)}><i
                                                                                className="fas fa-solid fa-arrows-left-right-to-line fa-rotate-270"></i></div>
                                                                        </td>
                                                                        <td className="p-0">{moment.unix(ping.timeStamp).format("YYYY-MM-DD HH:mm")}</td>
                                                                        <td className="p-0">
                                                                            <span>{(ping.location_id == null ? ping.description : "")}</span>
                                                                            <span className={(ping.location_id == null ? "d-none" : "")}>
                                                                                <span className="btn btn-text m-0 p-0" onClick={() => this.toggle_location(ping.location_id)}>
                                                                                    <i className={(typeof this.state.locations[ping.location_id] != "undefined" && this.state.locations[ping.location_id].is_stop == 0 ? "far fa-hourglass text-success mr-1" : "d-none")}
                                                                                       title="Location is not a stop or destination"></i>
                                                                                    <i className={(typeof this.state.locations[ping.location_id] != "undefined" && this.state.locations[ping.location_id].is_stop == 1 ? "fas fa-hourglass-half text-warning mr-1" : "d-none")}
                                                                                       title="Location is a stop"></i>
                                                                                    <i className={(typeof this.state.locations[ping.location_id] != "undefined" && this.state.locations[ping.location_id].is_stop == 2 ? "fas fa-hourglass-end text-danger mr-1" : "d-none")}
                                                                                       title="Location is a destination"></i>
                                                                                    {(typeof this.state.locations[ping.location_id] == "undefined" || ping.location_id == null ? "" : this.state.locations[ping.location_id].name)}
                                                                                </span>
                                                                            </span>
                                                                        </td>
                                                                        <td className={(ping.is_ignition == 1 ? "p-0 text-success" : (ping.is_ignition == 2 ? "p-0 text-dark" : ""))}>{(ping.is_ignition == 1 ? "On" : (ping.is_ignition == 2 ? "Off" : ""))}</td>
                                                                        <td className="p-0">{ping.speed_km} km/hr</td>
                                                                        <td className="p-0">{(ping.speed_limit > 0 ? ping.speed_limit + " km/hr" : "")}</td>
                                                                        <td className="p-0" title={ping.direction}><i
                                                                            className={(rotate === false ? "d-none" : "fas fa-location-arrow")}
                                                                            style={{transform: "rotate(" + rotate + "deg"}}></i></td>
                                                                        <td className="p-0">{ping.distance_travelled}</td>
                                                                    </tr>
                                                                )
                                                            }, this)}
                                                            </tbody>
                                                        </table>
                                                        <div className={(segment.show_pings == false ? "d-none" : "text-center")}>
                                                            <div className="btn btn-xs pt-0 pb-0 btn-light w-100" onClick={() => this.togglePings(segment_num)}>
                                                                <small>
                                                                    <i className="fas fa-chevron-up mr-1"></i> Hide Pings
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }, this)}
                                    </tbody>
                                </table>
                            </div>
                        </Form>
                    </div>
                    <ModalFooter>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle disabled={(this.state.processing)} className={(this.state.merge_load_ids.length == 0 ? "d-none" : "")} size="sm" color="primary" caret>
                                <i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "fas fa-recycle")}></i> OVERWRITE Load
                            </DropdownToggle>
                            <DropdownMenu>
                                {Object.keys(this.state.merge_load_ids).map((key, i) => {
                                    return (<DropdownItem key={key}
                                                          onClick={() => this.mergeLoad('overwrite', this.state.merge_load_ids[key])}># {this.state.merge_load_ids[key]}</DropdownItem>)
                                }, this)}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle disabled={(this.state.processing)} className={(this.state.merge_load_ids.length == 0 ? "d-none" : "")} size="sm" color="primary" caret>
                                <i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "fas fa-filter")}></i> ADD TO Load
                            </DropdownToggle>
                            <DropdownMenu>
                                {Object.keys(this.state.merge_load_ids).map((key, i) => {
                                    return (<DropdownItem key={key}
                                                          onClick={() => this.mergeLoad('add', this.state.merge_load_ids[key])}># {this.state.merge_load_ids[key]}</DropdownItem>)
                                }, this)}
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                        <Button color="primary" className={(typeof this.props.template_id == "undefined" ? "" : "d-none")} onClick={this.createLoad}
                                disabled={(this.state.processing)}><i
                            className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "fas fa-plus")}></i> Create as NEW Load</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal_add} fade={false} toggle={this.toggle_add} size="sm">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Additional Trips</div>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            Click to add additional driver logged trips
                        </div>
                        <div>
                            {this.state.related.map(function (ping, ping_num) {
                                return (<button className="btn btn-primary mb-1" disabled={(this.state.loaded_log_id.includes(ping.drivers_logged_id))}
                                                onClick={() => this.getLog(ping.drivers_logged_id)}>{(typeof this.state.drivers[ping.driver_id] == "undefined" ? "" : this.state.drivers[ping.driver_id].first_name)}: {moment(ping.in_time).format("MMM D HH:mm")} - {(ping.out_time != null ? moment(ping.out_time).format("MMM D HH:mm") : "NA")}</button>)
                            }, this)}
                        </div>
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.location_modal} fade={false} toggle={this.toggle_location} fullscreen size="xl">
                    <div className="modal-header">
                        <div className="modal-title">
                            <div className="h3 mb-0">
                                Location # {(typeof this.state.locations[this.state.location_id] == "undefined" ? "" : this.state.locations[this.state.location_id].name)}
                            </div>
                        </div>
                        <div className="btn btn-light btn-sm" onClick={this.toggle_location}>X</div>
                    </div>
                    <ModalBody className="pt-0">
                        <LocationDisplay location_id={this.state.location_id} show_trips={false} update_locations={this.update_locations}/>
                    </ModalBody>
                </Modal>
            </>
        );
    };
}

export default (LoggedTrip);
