import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class AddDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            processing: false,
            loading: true
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    addDriver = async event => {
        event.preventDefault()
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        var state = this.state;
        axios.post(API_ROOT + '/driver', state)
            .then(function (response) {
                self.props.loadDrivers();
                self.setState({
                    modal: false
                });
                Swal("Success!", response.data.Message, "success");
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <FormGroup>
                <Button color="primary" onClick={this.toggle}><i className="fas fa-plus"></i> Driver</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Driver</div>
                    </ModalHeader>
                    <ModalBody className="card card-default">
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>First Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="first_name" value={this.state.first_name} onChange={(event) => this.updateField("first_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Last Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="last_name" value={this.state.last_name} onChange={(event) => this.updateField("last_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Phone</Label>
                                <Col sm={8}>
                                    <Input type="text" name="phone" placeholder="+15060000000" value={this.state.phone} onChange={(event) => this.updateField("phone", event)}/>
                                    <small>Must be in +1XXXXXXXXXX format</small>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Email</Label>
                                <Col sm={8}>
                                    <Input type="email" name="email" value={this.state.email} onChange={(event) => this.updateField("email", event)}/>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.addDriver}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Add Driver</Button>
                    </ModalFooter>
                </Modal>
            </FormGroup>
        );
    };
}

export default (AddDriver);