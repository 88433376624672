import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import 'moment-timezone';
import AddFillup from '../Fillups/AddFillup';
import {getVehicles} from "../Vehicles/VehicleFunctions";
import {getDrivers} from "../Drivers/DriverFunctions";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown} from "reactstrap";
import {LineChart, BarChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';

moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class FillupsList extends React.Component {
    constructor(props) {
        super(props);

        this.updateDates = this.updateDates.bind(this);
        this.refreshFillup = this.refreshFillup.bind(this);

        this.state = {
            vehicles: {},
            vehicle_id: (localStorage.getItem('vehicle_id') != null ? localStorage.getItem('vehicle_id') : null),
            vehicles_sort: [],
            staff: [],
            driver_id: null,
            drivers: {},
            loading: true,
            fillups: {
                list: [],
            },
            fillup: {
                fillup_id: null,
                equipment: "(select vehicle)",
                driver: "(select driver)",
                equipment_id: null,
                fill_date: null,
                odometer: "",
                fuel_litres: "",
                fuel_ppl: "",
                pst_fuel: "",
                gst_fuel: "",
                fuel_tax: money.floatToAmount(0),
                fuel_total_cost: money.floatToAmount(0),
                other: "",
                tax_other: "",
                total_other: money.floatToAmount(0),
                total: money.floatToAmount(0),
                location: "",
                chain: "(select chain)",
                driver_id: null,
            },
            chart_periods: ["Days", "Weeks", "Months"],
            line_data: [],
            line_labels: {},
            bar_data: [],
            chart_period: (localStorage.getItem('chart_period') != null ? localStorage.getItem('chart_period') : "Weeks"),

            startDate: (localStorage.getItem('fu_startDate') != null ? moment(localStorage.getItem('fu_startDate')) : moment("14 May 2023")),
            endDate: (localStorage.getItem('fs_endDate') != null ? moment(localStorage.getItem('fs_endDate')) : moment().endOf('day')),
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'This Year': [moment().startOf('year'), moment().subtract(1, 'day').endOf('day')],
                'All Time': [moment("14 May 2023"), moment().endOf('day')]
            },
            sort: (localStorage.getItem('sort') != null ? localStorage.getItem('sort') : "name"),
            sort_order: (localStorage.getItem('sort_order') != null ? localStorage.getItem('sort_order') * -1 : -1),
        };
    }

    componentDidMount() {
        document.title = "Fill-Ups | Greenhaven Transportation";
        var self = this;
        getDrivers(function (drivers) {
            getVehicles(function (vehicles) {
                if (Object.keys(vehicles).length > 0) {
                    var vehicles_sort = [];
                    for (const vehicle_id of Object.keys(vehicles)) {
                        if (vehicles[vehicle_id].is_vehicle == 1) {
                            vehicles_sort.push(vehicles[vehicle_id])
                        }
                    }
                    vehicles_sort = vehicles_sort.sort(function (a, b) {
                        if (a.name < b.name) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                    self.setState({
                        drivers: drivers,
                        vehicles: vehicles,
                        vehicles_sort: vehicles_sort
                    },);
                    self.refreshFillup();
                    self.onSort(null, self.state.sort)
                }
            });
        });
    }

    refreshFillup = () => {
        var colorArray = ["#3366cc", "#dc3912", "#ff9900", "#109618",
            "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395",
            "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc", "#e67300",
            "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322",
            "#16d620", "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d",
            "#668d1c", "#bea413", "#0c5922", "#743411", "#333", "#999"];
        var self = this;
        this.setState({
            loading: true
        });
        axios.get(API_ROOT + '/fillups/?driver_id=' + this.state.driver_id + '&equipment_id=' + this.state.vehicle_id + "&startDate=" + self.state.startDate + "&endDate=" + self.state.endDate)
            .then(function (response) {
                var startDate = self.state.startDate.clone();
                var endDate = self.state.endDate.clone();
                var line_data = [];
                var line_labels = {};
                var bar_data = [];
                var first_pass = true;
                for (var m = startDate; m.diff(endDate, self.state.chart_period) < 0; m.add(1, self.state.chart_period)) {
                    var period_start = m.clone().startOf(self.state.chart_period.replace('s', ''));
                    var period_end = m.clone().endOf(self.state.chart_period.replace('s', '')).endOf('day');
                    var period = {"name": (self.state.chart_period == "Months" ? period_start.format("MMM") : period_start.format("MMM D"))}
                    for (const vehicle_id of Object.keys(self.state.vehicles)) {
                        var distance = 0;
                        var litres = 0;
                        var distance_total = 0;
                        var lites_total = 0;
                        for (const fillup of response.data) {
                            if (fillup.equipment_id == vehicle_id && fillup.mpg > 0) {
                                if (moment(fillup.fill_date).isBetween(period_start, period_end, null, '()')) {
                                    distance = distance + fillup.distance_km;
                                    litres = litres + fillup.fuel_litres;
                                }
                                distance_total = distance_total + fillup.distance_km;
                                lites_total = lites_total + fillup.fuel_litres;
                            }
                        }
                        period["_" + vehicle_id] = (distance == 0 ? null : ((distance / 1.609344) / (litres * 0.26417205)).toFixed(1));
                        if (distance > 0) {
                            line_labels[vehicle_id] = {id: "_" + vehicle_id, name: self.state.vehicles[vehicle_id].name, color: colorArray[vehicle_id]}
                        }
                        if (first_pass && distance_total > 0) {
                            bar_data.push({
                                name: self.state.vehicles[vehicle_id].name,
                                distance: distance_total,
                                mpg: ((distance_total / 1.609344) / (lites_total * 0.26417205)).toFixed(1)
                            });
                        }
                    }
                    first_pass = false;
                    line_data.push(period);
                }

                bar_data = bar_data.sort(function (a, b) {
                    if (parseFloat(a.mpg) > parseFloat(b.mpg)) {
                        return -1
                    } else {
                        return 1
                    }
                });

                self.setState(
                    {
                        line_data: line_data,
                        line_labels: line_labels,
                        bar_data: bar_data,
                        loading: false,
                        fillups: {
                            list: response.data,
                            loading: false,
                        }
                    })
                ;
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    // on date range change
    updateDates(event, picker) {
        if (event.type == "apply") {
            localStorage.setItem('fu_startDate', picker.startDate);
            localStorage.setItem('fu_endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, function () {
                this.refreshFillup();
            });
        }
    }

    updateValue(event, name, value) {
        var self = this;
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        localStorage.setItem(name, value);
        this.setState({
            [name]: value
        }, () => {
            self.refreshFillup();
        });
    }

    onSort(event, sortKey) {
        localStorage.setItem('sort', sortKey);
        localStorage.setItem('sort_order', this.state.sort_order);
        var sort_order = this.state.sort_order * -1;
        if (sortKey != this.state.sort) {
            sort_order = 1;
        }
        function format_search_val(val) {
            if (isNaN(val)) {
                return val.toLowerCase();
            } else {
                return parseFloat(val);
            }
        }

        var fillups = this.state.fillups;
        fillups['list'] = fillups.list.sort((a, b) => {
            if (typeof format_search_val(a[sortKey]) == "string" && typeof format_search_val(b[sortKey]) != "string") {
                return (sort_order == -1 ? -1 : 1);
            } else {
                if (format_search_val(a[sortKey]) > format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? -1 : 1);
                }
                if (format_search_val(a[sortKey]) < format_search_val(b[sortKey])) {
                    return (sort_order == -1 ? 1 : -1);
                }
            }
            return 0;
        });
        this.setState({
            fillups: fillups,
            sort_order: sort_order,
            sort: sortKey
        });
    }

    viewFillup = (fillup) => {
        this.setState({
            fillup: fillup
        })
    }

    render() {

        // date range selector variables
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        var total_litres = 0;
        var total_prices = 0;
        var count_prices = 0;
        var total_cost = 0;
        var total_def = 0;
        var total_distance = 0;
        var total_distance_mpg = 0;
        var total_cost_mpg = 0;

        const CustomTooltip = ({active, payload, label}) => {
            if (active && payload && payload.length) {
                payload = payload.sort(function (a, b) {
                    if (parseFloat(a.value) > parseFloat(b.value)) {
                        return -1
                    } else {
                        return 1
                    }
                });
                return (
                    <div className="recharts-tooltip-wrapper recharts-tooltip-wrapper-top recharts-tooltip-wrapper-right p-1">
                        <div className="recharts-default-tooltip bg-light shadow-sm border-radius border-light">
                            <b className="label">{`${label}`}</b>
                            <div>
                                {payload.map((pld) => (
                                    <div>
                                        <div style={{color: pld.color}}>{pld.name}: {pld.value} MPG</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            }
            return null;
        };

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Fill-Ups</div>
                    <div className="ml-auto">
                        <AddFillup refreshFillup={this.refreshFillup} size="sm" viewFillup={this.state.fillup}/>
                    </div>
                </div>
                <div className="mb-3 form-inline" style={{zIndex: 1000}}>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        ranges={this.state.ranges}
                        maxDate={moment()}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <button type="button" className="btn btn-primary selected-date-range-btn mr-1">
                            Summary Period:* <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </button>
                    </DateRangePicker>
                    <UncontrolledButtonDropdown className="ml-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            {(this.state.vehicle_id == null || typeof this.state.vehicles[this.state.vehicle_id] == "undefined" ? "All Vehicles" : this.state.vehicles[this.state.vehicle_id].name)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.updateValue(false, 'vehicle_id', null)}>All Vehicles</DropdownItem>
                            <DropdownItem divider/>
                            {this.state.vehicles_sort.map((equipment, i) => {
                                return (<DropdownItem key={equipment.equipment_id}
                                                      onClick={() => this.updateValue(false, 'vehicle_id', equipment.equipment_id)}>{equipment.name}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <UncontrolledButtonDropdown className="ml-1">
                        <DropdownToggle color="primary" caret style={{fontSize: '13px', width: 'auto'}}>
                            Graph by: {this.state.chart_period}
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.state.chart_periods.map((period, i) => {
                                return (<DropdownItem key={period} onClick={() => this.updateValue(false, 'chart_period', period)}>{period}</DropdownItem>)
                            })}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div>
                        <div className="card-header">
                            <div className="card-title">{this.state.fillups.list.length.toLocaleString()} Fill-Ups</div>
                        </div>
                        <div className="card-body">
                            <div style={{width: "100%", height: "550px"}} className={(this.state.line_data.length > 0 ? "" : "d-none")}>
                                <ResponsiveContainer>
                                    <LineChart
                                        width={500}
                                        height={550}
                                        data={this.state.line_data}
                                        margin={{
                                            top: 0,
                                            right: 0,
                                            left: 20,
                                            bottom: 35,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name" angle={-45} textAnchor="end"/>
                                        <YAxis domain={['dataMin', 'auto']} tickFormatter={(tickValue) => (tickValue).toFixed(1) + ' MPG'}/>
                                        <Tooltip content={<CustomTooltip/>}/>
                                        <Legend layout="horizontal" verticalAlign="top" align="center"/>
                                        {
                                            Object.keys(this.state.line_labels).map((id, key) => {
                                                return (<Line key={`line_${id}`} dataKey={this.state.line_labels[id].id} name={this.state.line_labels[id].name} type="monotone"
                                                              stroke={this.state.line_labels[id].color} dot={false}/>)
                                            })
                                        }
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                            <div style={{width: "100%", height: "300px"}} className={(this.state.bar_data.length > 0 ? "" : "d-none")}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        width={500}
                                        height={300}
                                        data={this.state.bar_data}
                                        margin={{
                                            top: 20,
                                            right: 20,
                                            left: 20,
                                            bottom: 30,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="name" angle={-45} textAnchor="end"/>
                                        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" tickFormatter={(tickValue) => (tickValue).toLocaleString() + ' KM'}
                                               padding={{left: 0, right: 0}}/>
                                        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" tickFormatter={(tickValue) => (tickValue).toFixed(1) + ' MPG'}/>
                                        <Tooltip/>
                                        <Legend layout="horizontal" verticalAlign="top" align="center"/>
                                        <Bar yAxisId="left" dataKey="distance" fill="#8884d8"/>
                                        <Bar yAxisId="right" dataKey="mpg" fill="#82ca9d"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                            <div className="table-responsive">
                                <table className={(this.state.fillups.list.length > 0 ? "table table-sm table-hover table-pointer text-right" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="text-left" onClick={e => this.onSort(e, 'chain')}><i className="fas fa-sort"></i> Chain</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'fill_date')}><i className="fas fa-sort"></i> Date</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'vehicle_id')}><i className="fas fa-sort"></i> Vehicle</th>
                                        <th className="text-left" onClick={e => this.onSort(e, 'driver_id')}><i className="fas fa-sort"></i> Driver</th>
                                        <th onClick={e => this.onSort(e, 'odometer')}><i className="fas fa-sort"></i> Odometer</th>
                                        <th onClick={e => this.onSort(e, 'fuel_litres')}><i className="fas fa-sort"></i> Litres</th>
                                        <th onClick={e => this.onSort(e, 'fuel_ppl')}><i className="fas fa-sort"></i> Price per Litre</th>
                                        <th onClick={e => this.onSort(e, 'fuel_total_cost')}><i className="fas fa-sort"></i> Fuel Total</th>
                                        <th onClick={e => this.onSort(e, 'def_total_cost')}><i className="fas fa-sort"></i> DEF Total</th>
                                        <th onClick={e => this.onSort(e, 'mpg')}><i className="fas fa-sort"></i> MPG</th>
                                        <th onClick={e => this.onSort(e, 'distance_km')}><i className="fas fa-sort"></i> KM Trip</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.fillups.list.map(function (item, key) {
                                        var self = this;
                                        if (item.fuel_ppl > 0) {
                                            total_prices = total_prices + item.fuel_ppl;
                                            count_prices = count_prices + 1;
                                        }
                                        total_litres = total_litres + item.fuel_litres;
                                        total_cost = total_cost + item.fuel_total_cost;
                                        total_def = total_def + item.def_total_cost;
                                        total_distance = total_distance + item.distance_km;
                                        if (item.mpg > 0) {
                                            total_distance_mpg = total_distance_mpg + item.distance_km;
                                            total_cost_mpg = total_cost_mpg + item.fuel_total_cost;
                                        }
                                        return (
                                            <tr key={key} onClick={this.viewFillup.bind(this, item)}>
                                                <td className="text-left">{item.chain}</td>
                                                <td className="text-left">{moment(item.fill_date).format("MMM. D/YY HH:mm")}</td>
                                                <td className="text-left"><Link
                                                    to={"../vehicle/" + item.equipment_id}>{(item.equipment_id == null ? "" : self.state.vehicles[item.equipment_id].name)}</Link>
                                                </td>
                                                <td className="text-left"><Link
                                                    to={"../driver/" + item.driver_id}>{(item.driver_id == null ? "" : self.state.drivers[item.driver_id].first_name + " " + self.state.drivers[item.driver_id].last_name)}</Link>
                                                </td>
                                                <td>{(item.odometer == null ? "" : item.odometer.toLocaleString())}</td>
                                                <td>{item.fuel_litres}</td>
                                                <td>${item.fuel_ppl}</td>
                                                <td>${money.format("USD", money.floatToAmount(item.fuel_total_cost))}</td>
                                                <td>${money.format("USD", money.floatToAmount(item.def_total_cost))}</td>
                                                <td>{(item.mpg == null ? "--" : item.mpg.toFixed(1))}</td>
                                                <td>{(item.distance_km == null ? "--" : item.distance_km.toLocaleString())}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colspan="4" className="text-right">Total (*Avg):</th>
                                        <th>{total_litres.toLocaleString()} L</th>
                                        <th>*${(total_prices / count_prices).toFixed(2)}/L</th>
                                        <th>${money.format("USD", money.floatToAmount(total_cost))}</th>
                                        <th>${money.format("USD", money.floatToAmount(total_def))}</th>
                                        <th>{((total_distance_mpg / total_cost_mpg) / 235.215 * 1000).toFixed(2)} MPG</th>
                                        <th>{total_distance.toLocaleString()} KM</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (FillupsList);