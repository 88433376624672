import * as React from 'react';

function Pin(details: {
    speed: number | null,
    dir: null,
    is_ignit: null,
    timestamp: 0,
    timestamp_map: 0,
    name: "",
    show_names: false,
    status: number | null,
    show_active: 1;
}) {
    var rotate = 0;
    var is_active = false;

    if (
        (details.speed != null && details.speed > 0 && details.dir == null) ||
        (details.is_ignit == 1 && details.timestamp < details.timestamp_map - (60 * 60 * 1)) || // truck
        (details.is_ignit == null && details.timestamp < details.timestamp_map - (60 * 60 * 3)) // trailer
    ) {
        // question
        var icon = `M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z`;
    } else if (details.is_ignit === 2 || details.speed == null) {
        // cross
        var icon = `M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z`;
    } else if (details.speed == 0) {
        // pause
        var icon = `M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm-16 328c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v160zm112 0c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v160z`;
        is_active = true;
    } else {
        // arrow
        var icon = `M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm292 116V256h70.9c10.7 0 16.1-13 8.5-20.5L264.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3c-7.6 7.6-2.2 20.5 8.5 20.5H212v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z`;
        if (details.dir == "ne") {
            rotate = 45;
        } else if (details.dir == "e") {
            rotate = 90;
        } else if (details.dir == "se") {
            rotate = 140;
        } else if (details.dir == "s") {
            rotate = 180;
        } else if (details.dir == "sw") {
            rotate = 226;
        } else if (details.dir == "w") {
            rotate = 270;
        } else if (details.dir == "nw") {
            rotate = 315;
        }
        is_active = true;
    }
    if (details.is_ignit === null) {
        var fill = "#5b5b5b"; // grey
    } else if (details.is_ignit == 1 && details.timestamp < details.timestamp_map - (60 * 60 * 3)) {
        var fill = "#F27B12"; // orange
    } else if (details.is_ignit == 2) {
        var fill = "#d00"; // red
    } else {
        var fill = "#00c600"; // green
    }
    var pinStyle = {
        cursor: 'pointer',
        fill: fill,
        background: '#fff',
        borderRadius: "50%",
        opacity: (details.status == 3 ? "50%" : "100%"),
        stroke: 'none',
        transform: "rotate(" + rotate + "deg)"
    };
    return (
        <div className={(details.show_active !== true || is_active ? "" : "d-none")}>
            <div className={(details.show_names === true ? "shadow-sm" : "d-none")}
                 style={{color: "#000", marginBottom: "-6px", textAlign: "center", marginLeft: "-5px", marginRight: "5px", marginTop: "-13px"}}>
                <span className="bg-light rounded" style={{paddingLeft: "2px", paddingRight: "2px"}}>{details.name}</span>
            </div>
            <svg height={20} viewBox="0 0 512 512" style={pinStyle}>
                <path d={icon}/>
            </svg>
        </div>
    );
}

export default React.memo(Pin);
