import React, {Component} from 'react';
import {
    Card,
    CardBody,
    Row,
    Col,
    Input,
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownToggle,
    DropdownItem,
    ButtonGroup,
    UncontrolledTooltip,
    Button,
    ModalBody, FormGroup, InputGroupAddon, InputGroup, ModalHeader, Modal
} from 'reactstrap';
import ContentWrapper from '../Layout/ContentWrapper';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment-timezone';
import {toast} from 'react-toastify';
import SingleDatePicker from 'react-bootstrap-daterangepicker';
import Select, {components} from 'react-select';

import {getDrivers} from '../Drivers/DriverFunctions';
import AddLocation from '../Locations/AddLocation';
import getLocations from '../Locations/LocationFunctions';

import {getEquipmentDetails, getEquipmentTypes, getEquipmentTypesSub} from "../Equipment/EquipmentFunctions";
import {MapLoad} from "./Map";
import {Link} from "react-router-dom";
import TemplateCreate from "./TemplateCreate";

moment.tz.setDefault("America/Halifax");

var money = require("money-math");

class QuoteDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_equipment: true,
            loading_segments: true,
            page: 'details',
            tender_date: moment(),
            description: "",
            linestring: {
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "type": "MultiLineString",
                    "coordinates": []
                }
            },
            points: [],
            equipment: {},
            equipment_sort: [],
            vehicle_sort: [{options: []}],

            vehicle_id: null,
            vehicle_type_id: null,
            vehicle_types: [],
            vehicle_type_sub_id: null,
            vehicle_types_sub: [],

            equipment_id: null,
            equipment_type_id: null,
            equipment_types: [],
            equipment_type_sub_id: null,
            equipment_types_sub: [],

            segments: [{
                segment_num: 0,
                origin: null,
                destination: null,
                start: moment().format('MMM. D/YY HH:mm:00'),
                distance_miles: "",
                wait_hrs: 0,
                duration_hrs: "",
                distance_miles_computed: null,
                duration_hrs_computed: null,
                container_num: "",
                tolls: 0,
                billable: 1,
            }],
            last_segment_check: 0,
            temp_segment: false,
            temp_point: false,
            qty_miles: 0,
            qty_miles_billable: 0,
            target_margin: 20,

            driver_id: null,
            drivers: {},
            drivers_sort: [],

            location_search_modal: false,
            search_term: "",
            locations: {},
            locations_sort: [],

            new_equip_segment_num: 0,

            qty_hrs_total: 0,
            qty_hrs_total_billable: 0,
            qty_hrs_wait: 0,
            qty_hrs_wait_billable: 0,

            mpg: 0,
            price_per_litre: 0,
            vehicle_cost_km: 0,
            vehicle_cost_day: 0,
            total_vehicle_cost: 0,
            man_total_vehicle_cost: 0,

            total_admin_cost: 0,
            man_total_admin_cost: 0,

            labour_miles: 0,
            labour_hr: 0,
            labour_drops: 0,
            total_labour: 0,
            labour_rates: {},

            rate_tolls: 0,

            status: null,
            invoice_id: "",

            trigger_open_vehicle: false,

            file: null, // the uploaded file
        };
        this.updateValue = this.updateValue.bind(this);
        this.updatePayment = this.updatePayment.bind(this);
        this.location_search_toggle = this.location_search_toggle.bind(this);
        this.addSegment = this.addSegment.bind(this);
        this.timer = null;
    }

    componentDidMount() {
        var self = this;
        axios.defaults.withCredentials = true;
        getEquipmentTypes(function (vehicle_types) {
            getEquipmentTypesSub(function (vehicle_types_sub) {
                getDrivers(function (drivers) {
                    getLocations(function (locations) {
                        var drivers_sort = [];
                        for (const driver_id of Object.keys(drivers)) {
                            drivers_sort.push({
                                value: drivers[driver_id].driver_id,
                                label: drivers[driver_id].first_name + " " + drivers[driver_id].last_name
                            });
                        }
                        drivers_sort = drivers_sort.sort(function (a, b) {
                            if (a.label < b.label) {
                                return -1
                            } else {
                                return 1
                            }
                        });

                        self.setState({
                            locations: locations.locations,
                            locations_sort: locations.locations_sort,
                            drivers: drivers,
                            drivers_sort: drivers_sort,
                            vehicle_types: vehicle_types,
                            vehicle_types_sub: vehicle_types_sub,
                            equipment_types: vehicle_types,
                            equipment_types_sub: vehicle_types_sub,
                        });
                        self.refreshEquipment();
                        self.load_the_load();
                    });
                });
            });
        });
    }

    load_the_load = () => {
        var self = this;
        axios.get(API_ROOT + '/loads/0')
            .then(function (response) {
                self.setState({
                    loading: false,
                    description: response.data.description,
                    driver_id: response.data.driver_id,
                    customer_id: response.data.customer_id,
                    vehicle_id: response.data.vehicle_id,
                    vehicle_type_id: response.data.vehicle_type_id,
                    vehicle_type_sub_id: response.data.vehicle_type_sub_id,
                    equipment_id: response.data.equipment_id,
                    equipment_type_id: response.data.equipment_type_id,
                    equipment_type_sub_id: response.data.equipment_type_sub_id,
                    cust_load_num: response.data.cust_load_num,
                    bol: response.data.bol,
                    tender_date: (response.data.tender_date == null ? moment() : moment(response.data.tender_date).add(4, 'hours')),
                    attachments: response.data.attachments,
                    avoid_borderCrossing: response.data.avoid_borderCrossing,
                    avoid_minimizeUTurn: response.data.avoid_minimizeUTurn,
                    avoid_ferry: response.data.avoid_ferry,
                    qty_fixed: response.data.qty_fixed,
                    rate_fixed: response.data.rate_fixed,
                    qty_miles: response.data.qty_miles,
                    qty_miles_billable: response.data.qty_miles_billable,
                    rate_miles: response.data.rate_miles,
                    rate_fsc: response.data.rate_fsc,
                    rate_fixed_fsc: response.data.rate_fixed_fsc,
                    qty_fsc: response.data.qty_fsc,
                    rate_other: response.data.rate_other,
                    qty_other: response.data.qty_other,
                    status: response.data.status,
                    invoice_id: response.data.invoice_id,
                    man_tolls: response.data.man_tolls,
                    qty_drops: response.data.qty_drops,
                    rate_drops: response.data.rate_drops,
                    rate_tolls: response.data.rate_tolls,
                    rate_tolls_billable: response.data.rate_tolls_billable,
                    qty_hrs_total_billable: response.data.qty_hrs_total_billable,
                    qty_hrs_total: response.data.qty_hrs_total,
                    rate_hr: response.data.rate_hr,
                    rate_wait: response.data.rate_wait,
                    tax_miles: response.data.tax_miles,
                    tax_fixed: response.data.tax_fixed,
                    tax_fsc: response.data.tax_fsc,
                    tax_fixed_fsc: response.data.tax_fixed_fsc,
                    tax_other: response.data.tax_other,
                    tax_drops: response.data.tax_drops,
                    tax_wait: response.data.tax_wait,
                    tax_hr: response.data.tax_hr,
                    labour_miles: response.data.labour_miles,
                    labour_hr: response.data.labour_hr,
                    labour_drops: response.data.labour_drops,
                    total_labour: response.data.total_labour,
                    labour_rates: response.data.labour_rates,
                    price_per_litre: response.data.price_per_litre,
                    mpg: response.data.mpg,
                    vehicle_cost_day: response.data.vehicle_cost_day,
                    vehicle_cost_km: response.data.vehicle_cost_km,
                    total_vehicle_cost: response.data.total_vehicle_cost,
                    man_total_vehicle_cost: response.data.man_total_vehicle_cost,
                    total_admin_cost: response.data.total_admin_cost,
                    man_total_admin_cost: response.data.man_total_admin_cost,
                    reverse_billing_adj: response.data.reverse_billing_adj,
                    reverse_billing_tax_adj: response.data.reverse_billing_tax_adj

                }, function () {
                    self.refreshEquipment();
                    self.getSegments();
                    setInterval(self.checkSegments, 2000);
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error11", error.response.data.Message, "error");
                }
            });
    }

    refreshEquipment = async event => {
        var self = this;
        self.setState({
            loading_equipment: true,
        });
        getEquipmentDetails(1, 0, null, await function (equipment) {
            equipment[2] = {
                "equipment_id": 2,
                "name": "2 Axle Trailer",
                "axles": 2,
                "equipment_type_id": 5,
                "equipment_type_sub_id": 21,
                "status": 1,
                "cost_day": 0,
                "cost_km": 0,
                "is_vehicle": 0,
                "last_moved": null,
            }
            equipment[26] = {
                "equipment_id": 26,
                "name": "3 Axle Trailer",
                "axles": 3,
                "equipment_type_id": 5,
                "equipment_type_sub_id": 22,
                "status": 1,
                "cost_day": 0,
                "cost_km": 0,
                "is_vehicle": 0,
                "last_moved": null,
            }
            console.log(equipment)
            self.setState({
                loading_equipment: false,
                equipment: equipment
            }, function () {
                self.equipmentOptions();
                self.vehicleOptions();
            });
            if (self.state.equipment_id != null && self.state.equipment_type_id != null && equipment[self.state.equipment_id].equipment_type_id != self.state.equipment_type_id) {
                self.setState({equipment_type_id: equipment[self.state.equipment_id].equipment_type_id});
            }
            if (self.state.vehicle_id != null && self.state.vehicle_type_id != null && equipment[self.state.vehicle_id].equipment_type_id != self.state.vehicle_type_id) {
                self.setState({vehicle_type_id: equipment[self.state.vehicle_id].equipment_type_id});
            }
            if (self.state.equipment_id != null && self.state.equipment_type_sub_id != null && equipment[self.state.equipment_id].equipment_type_sub_id != self.state.equipment_type_sub_id) {
                self.setState({equipment_type_sub_id: equipment[self.state.equipment_id].equipment_type_sub_id});
            }
            if (self.state.vehicle_id != null && self.state.vehicle_type_sub_id != null && equipment[self.state.vehicle_id].equipment_type_sub_id != self.state.vehicle_type_sub_id) {
                self.setState({vehicle_type_sub_id: equipment[self.state.vehicle_id].equipment_type_sub_id});
            }
            if (self.state.status < 3 && self.state.vehicle_id > 0 && typeof equipment[self.state.vehicle_id] != "undefined") { // if not marked completed or billed, use live data
                self.setState({
                    price_per_litre: equipment[self.state.vehicle_id].ppl,
                    mpg: equipment[self.state.vehicle_id].avg_mpg,
                    vehicle_cost_day: equipment[self.state.vehicle_id].cost_day,
                    vehicle_cost_km: equipment[self.state.vehicle_id].cost_km,
                })
            }
        });
    }

    equipmentOptions = event => {
        var equipment_sort = [];
        var equipment = this.state.equipment;
        var temp = {name: 'Greenhaven', options: []};
        for (const equipment_id in equipment) {
            if (
                equipment[equipment_id].is_vehicle == 0
            ) {
                var name = equipment[equipment_id].name;
                var type = " " + this.state.equipment_types[equipment[equipment_id].equipment_type_id].name + ", " + this.state.equipment_types_sub[equipment[equipment_id].equipment_type_sub_id].name;
                temp.options.push({value: equipment_id, label: name, type: type, status: equipment[equipment_id].status})
            }
        }
        temp.options = temp.options.sort(function (a, b) {
            if (a.label < b.label) {
                return -1
            } else {
                return 1
            }
        });
        equipment_sort.push(temp);
        this.setState({
            equipment_sort: equipment_sort
        })
    }

    vehicleOptions = event => {
        var vehicle_sort = [];
        var customer_id = 0;
        var equipment = this.state.equipment;
        var temp = {name: (customer_id == 0 ? 'Greenhaven' : this.state.customers[customer_id].name), options: []};
        for (const equipment_id of Object.keys(equipment)) {
            if (
                equipment[equipment_id].customer_id == customer_id &&
                equipment[equipment_id].is_vehicle == 1 &&
                equipment[equipment_id].status != 0 &&
                (equipment[equipment_id].equipment_type_id == this.state.vehicle_type_id || this.state.vehicle_type_id == null) &&
                (equipment[equipment_id].equipment_type_sub_id == this.state.vehicle_type_sub_id || this.state.vehicle_type_sub_id == null)
            ) {
                var dwell = (equipment[equipment_id].last_moved == null ? "" : "(" + moment().diff(equipment[equipment_id].last_moved, "days") + ")");
                temp.options.push({value: equipment[equipment_id].equipment_id, label: equipment[equipment_id].name, status: equipment[equipment_id].status, dwell: dwell})
            }
        }
        temp.options = temp.options.sort(function (a, b) {
            if (a.label < b.label) {
                return -1
            } else {
                return 1
            }
        });
        vehicle_sort.push(temp);
        this.setState({
            vehicle_sort: vehicle_sort
        })
    }

    checkSegments = event => {
        var trigger_check = false;
        var points = [];
        var segment_num = 0;
        var num_segments = this.state.segments.length;
        var qty_miles = money.floatToAmount(0);
        var qty_miles_billable = money.floatToAmount(0);
        var qty_hrs_wait = money.floatToAmount(0);
        var qty_hrs_wait_billable = money.floatToAmount(0);
        var qty_hrs_total = money.floatToAmount(0);
        var qty_hrs_total_billable = money.floatToAmount(0);
        var rate_tolls = money.floatToAmount(0);
        for (const segment of this.state.segments) {
            var origin = segment.origin;
            var destination = segment.destination;
            var locations = this.state.locations;
            if (segment.distance_miles_computed == null && origin != null && destination != null) {
                trigger_check = true;
            }
            if (origin != null && typeof locations[origin] != "undefined") {
                points.push({
                    lat: locations[origin].lat,
                    lon: locations[origin].lon,
                    name: locations[origin].name,
                    address: locations[origin].address,
                    type: (segment_num == 0 ? "start" : "reg")
                })
            }
            if (destination != null && typeof locations[destination] != "undefined") {
                points.push({
                    lat: locations[destination].lat,
                    lon: locations[destination].lon,
                    name: locations[destination].name,
                    address: locations[destination].address,
                    type: (segment_num + 1 == num_segments ? "end" : "reg")
                })
            }
            if (segment.distance_miles != null) {
                if (segment.billable == 1) {
                    qty_miles_billable = money.add(qty_miles_billable, money.floatToAmount(segment.distance_miles));
                }
                qty_miles = money.add(qty_miles, money.floatToAmount(segment.distance_miles));
            }
            if (segment.wait_hrs != null) {
                if (segment.billable == 1 && segment.wait_hrs > 1) {
                    var hrs_wait_billable = segment.wait_hrs - 1;
                    qty_hrs_wait_billable = money.add(qty_hrs_wait_billable, money.floatToAmount(hrs_wait_billable));
                    //qty_hrs_total_billable = money.add(qty_hrs_total_billable, money.floatToAmount(segment.wait_hrs));
                }
                qty_hrs_wait = money.add(qty_hrs_wait, money.floatToAmount(segment.wait_hrs));
                //qty_hrs_total = money.add(qty_hrs_total, money.floatToAmount(segment.wait_hrs));
            }
            if (segment.duration_hrs != null) {
                if (segment.billable == 1) {
                    qty_hrs_total_billable = money.add(qty_hrs_total_billable, money.floatToAmount(segment.duration_hrs));
                }
                qty_hrs_total = money.add(qty_hrs_total, money.floatToAmount(segment.duration_hrs));
            }
            rate_tolls = money.add(rate_tolls, money.floatToAmount(segment.tolls));
            segment_num++;
        }
        if (JSON.stringify(points) != JSON.stringify(this.state.points)) {
            this.setState({
                    points: points,
                }
            );
        }
        if (!money.isEqual(qty_miles, money.floatToAmount(this.state.qty_miles))) {
            this.setState({
                qty_miles: qty_miles
            });
        }
        if (!money.isEqual(qty_miles_billable, money.floatToAmount(this.state.qty_miles_billable))) {
            this.setState({
                qty_miles_billable: qty_miles_billable
            });
        }
        if (!money.isEqual(qty_hrs_wait, money.floatToAmount(this.state.qty_hrs_wait))) {
            this.setState({
                qty_hrs_wait: qty_hrs_wait
            });
        }
        if (!money.isEqual(qty_hrs_wait_billable, money.floatToAmount(this.state.qty_hrs_wait_billable))) {
            this.setState({
                qty_hrs_wait_billable: qty_hrs_wait_billable
            });
        }
        if (!money.isEqual(qty_hrs_total, money.floatToAmount(this.state.qty_hrs_total))) {
            this.setState({
                qty_hrs_total: qty_hrs_total
            });
        }
        if (!money.isEqual(qty_hrs_total_billable, money.floatToAmount(this.state.qty_hrs_total_billable))) {
            this.setState({
                qty_hrs_total_billable: qty_hrs_total_billable
            });
        }

        if (this.state.man_total_vehicle_cost != 1) {
            var total_vehicle_cost = money.mul(money.floatToAmount(this.state.vehicle_cost_km), money.floatToAmount(this.state.qty_miles * 1.609344));
            if (parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12) {
                total_vehicle_cost = money.add(total_vehicle_cost, money.floatToAmount(this.state.vehicle_cost_day));
            } else {
                total_vehicle_cost = money.add(total_vehicle_cost, money.floatToAmount(this.state.vehicle_cost_day / 2));
            }
            if (total_vehicle_cost != this.state.total_vehicle_cost) {
                console.log('updating total_vehicle_cost')
                this.setState({
                    total_vehicle_cost: total_vehicle_cost
                });
            }
        }
        if (this.state.man_total_admin_cost != 1 && this.state.status != 4) {
            if (parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12) {
                var total_admin_cost = money.floatToAmount(50);
            } else {
                var total_admin_cost = money.floatToAmount(50 / 2);
            }
            if (total_admin_cost != this.state.total_admin_cost) {
                this.setState({
                    total_admin_cost: total_admin_cost
                });
            }
        }
        if (rate_tolls != this.state.rate_tolls) {
            this.setState({
                rate_tolls: rate_tolls
            });
        }
        if (trigger_check) {
            this.getSegments();
        }
    }
    getSegments = () => {
        var current_timestamp = moment().unix();
        if (current_timestamp - this.state.last_segment_check > 2) {
            this.setState({last_segment_check: current_timestamp});
            var self = this;
            var current_segments = this.state.segments;
            axios.defaults.withCredentials = true;
            axios.get(API_ROOT + "/loads/0/segments")
                .then(function (response) {
                    var linestring = {
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "MultiLineString",
                            "coordinates": []
                        }
                    };
                    for (const segment of response.data) {
                        if (segment.route != null) {
                            var route = JSON.parse(segment.route)
                            linestring.geometry.coordinates.push(route.coordinates);
                        }
                    }
                    self.setState({
                            segments: response.data,
                            loading_segments: false,
                            linestring: linestring
                        }
                    );
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                    }
                });
        }
    }

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        var self = this;
        this.setState({
            [name]: value
        }, function () {
            self.autoSave(name)
        });
    };

    updateFieldSegment = (segment_num, name, value) => {
        if (typeof value != "undefined") {
            var segments = this.state.segments;
            if (name == "billable") {
                value = (segments[segment_num].billable == 1 ? 0 : 1);
            }
            if (value === true) {
                value = 1
            } else if (value === false) {
                value = 0
            } else if (typeof value != "string" && typeof value.target != "undefined") {
                value = value.target.value;
            }
            segments[segment_num][name] = value
            var self = this;
            this.setState({
                segments: segments
            }, function () {
                if (name != "show_dimensions") {
                    self.autoSave(name)
                }
                if (name == "description" || name == "container_num") {
                    self.promptApplyAll(name, value, segment_num);
                }
            });
        }
    };

    changeDriver = (segment_num, selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var driver_id = selectedOption; // used in callback function
        } else {
            var driver_id = selectedOption.value;
        }
        var segments = this.state.segments;
        segments[segment_num].driver_id = driver_id;
        var labour_rates = this.state.labour_rates;
        if (typeof labour_rates[driver_id] == "undefined") {
            labour_rates[driver_id] = {
                rate_miles: this.state.drivers[driver_id].rate_miles,
                rate_hr: this.state.drivers[driver_id].rate_hr,
                rate_drops: 0
            }
        }
        this.setState({
            segments: segments,
            labour_rates: labour_rates
        }, function () {
            this.sendSave('driver_id');
            this.promptApplyAll('driver_id', driver_id, segment_num);
        });
    };

    changeVehicleType = (vehicle_type_id) => {
        this.setState({
            vehicle_type_id: vehicle_type_id,
            vehicle_type_sub_id: null,
            vehicle_id: null
        }, function () {
            this.vehicleOptions();
            this.sendSave('vehicle_type_id');
        });
    };

    changeVehicleTypeSub = (vehicle_type_sub_id) => {
        this.setState({
            vehicle_type_sub_id: vehicle_type_sub_id,
            vehicle_id: null
        }, function () {
            this.vehicleOptions();
            this.sendSave('vehicle_type_sub_id');
        });
    }

    changeVehicle = (selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var vehicle_id = selectedOption; // used in callback function
        } else {
            var vehicle_id = selectedOption.value;
        }
        this.setState({
            mpg: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].avg_mpg),
            price_per_litre: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].ppl),
            vehicle_cost_km: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].cost_km),
            vehicle_cost_day: (typeof this.state.equipment[vehicle_id] == "undefined" ? 0 : this.state.equipment[vehicle_id].cost_day),
            vehicle_id: vehicle_id,
            vehicle_type_id: (vehicle_id == null ? this.state.vehicle_type_id : this.state.equipment[vehicle_id].equipment_type_id),
            vehicle_type_sub_id: (vehicle_id == null ? this.state.vehicle_type_sub_id : this.state.equipment[vehicle_id].equipment_type_sub_id)
        }, function () {
            this.sendSave('vehicle_id');
        });
    };

    promptApplyAll = (field, value, segment_num) => {
        var self = this;
        Swal.fire({
            title: 'Apply to Other Trips?',
            showCancelButton: true,
            confirmButtonText: '<i class="fas fa-arrows-alt-v"></i> All Trips',
            cancelButtonText: '<i class="fas fa-long-arrow-alt-down"></i> Trips Below',
            cancelButtonColor: '#005bbb',
            type: 'question',
            animation: false,
            toast: true,
            timer: 3000
        }).then((result) => {
            if (result.dismiss != 'timer') {
                var segments = self.state.segments;
                for (var key in segments) {
                    if (result.value || key >= segment_num) {
                        segments[key][field] = value;
                    }
                }
                self.setState({
                    segments: segments
                }, function () {
                    self.sendSave(field)
                });
            }
        });
    }

    changeEquipment = (segment_num, selectedOption) => {
        if (selectedOption == null || typeof selectedOption.value == "undefined") {
            var equipment_id = selectedOption; // used in callback function
        } else {
            var equipment_id = selectedOption.value;
        }
        var segments = this.state.segments;
        segments[segment_num].equipment_id = equipment_id
        if (typeof this.state.equipment[equipment_id] != "undefined") {
            segments[segment_num].axles = 3 + this.state.equipment[equipment_id].axles;
        } else {
            segments[segment_num].axles = 3;
        }
        this.setState({
            segments: segments,
        }, function () {
            this.sendSave('equipment_id');
            this.promptApplyAll('equipment_id', equipment_id, segment_num);
        });
    };

    updateValue(event, name, value) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (typeof name == "undefined") {
            name = event.target.name
        }
        if (typeof value == "undefined") {
            value = event.target.value
        }
        if (name == "total_vehicle_cost") {
            this.setState({man_total_vehicle_cost: 1})
        }
        if (name == "total_admin_cost") {
            this.setState({man_total_admin_cost: 1})
        }
        this.setState({
            [name]: value
        }, function () {
            if (name != "page" && name != "search_term") {
                this.autoSave(name)
            }
        });
    }

    updatePayment(driver_id, name, event) {
        if (event.target) {
            event.preventDefault();
            event.stopPropagation();
        }
        var value = event.target.value;
        var labour_rates = this.state.labour_rates;
        labour_rates[driver_id][name] = value;
        this.setState({
            labour_rates: labour_rates
        }, function () {
            this.autoSave(name)
        });
    }

    location_search_toggle = (segment_num, point) => {
        this.setState({
            temp_segment: segment_num,
            temp_point: point,
            search_term: "",
            location_search_modal: !this.state.location_search_modal
        }, function () {
            // set focus TODO
        });
    };

    updateLocation(segment, point, location_id) {
        var segments = this.state.segments;
        var self = this;
        getLocations(function (locations) {
            if (typeof segments[segment] != "undefined") {
                segments[segment][point] = location_id;
                segments[segment].distance_miles = "";
                segments[segment].duration_hrs = "";
                segments[segment].wait_hrs = 0;
                segments[segment].distance_miles_computed = null;
                segments[segment].duration_hrs_computed = null;
                self.setState({
                    locations: locations.locations,
                    locations_sort: locations.locations_sort,
                    segments: segments,
                    location_search_modal: false
                }, function () {
                    self.sendSave('location_id')
                });
            }
        });
    }

    location_saved = (location_id, name, address, city) => {
        var locations = this.state.locations;
        var self = this;
        locations[location_id] = {name: name, address: address, city: city}
        this.setState({
            location_search_modal: false,
            locations: locations
        }, function () {
            self.updateLocation(self.state.temp_segment, self.state.temp_point, location_id);
        });
    }

    addSegment = (is_reverse) => {
        var segments = this.state.segments;
        var num_segments = segments.length;
        var start = moment().format('MMM. D/YY HH:mm:00');
        if (num_segments > 0 && this.state.segments[num_segments - 1].start != null && this.state.segments[num_segments - 1].duration_hrs_computed != null) {
            start = moment(this.state.segments[num_segments - 1].start).add((Math.round((this.state.segments[num_segments - 1].duration_hrs_computed + this.state.segments[num_segments - 1].wait_hrs) * 60 / 10) * 10) + 10, 'minutes').format('MMM. D/YY HH:mm:00');
        }
        segments.push({
            segment_num: num_segments,
            origin: (num_segments > 0 ? this.state.segments[num_segments - 1].destination : null),
            destination: (is_reverse ? this.state.segments[num_segments - 1].origin : null),
            start: start,
            equipment_id: (num_segments > 0 ? this.state.segments[num_segments - 1].equipment_id : null),
            distance_miles: "",
            duration_hrs: "",
            wait_hrs: 0,
            billable: 1,
            tolls: 0,
            axles: (num_segments > 0 ? this.state.segments[num_segments - 1].axles : 0),
            distance_miles_computed: null,
            duration_hrs_computed: null,
        });
        this.setState({
            segments: segments
        }, function () {
            this.sendSave('segments')
        });
    }

    deleteSegment = (segment_id) => {
        var self = this;
        axios.defaults.withCredentials = true;
        Swal.fire({
            title: 'Are you sure?',
            text: "This segment will be permanently deleted and cannot be restored",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then((result) => {
            if (result.value) {
                axios.delete(API_ROOT + "/loads/0/segment/" + segment_id)
                    .then(function (response) {
                        self.getSegments();
                    })
                    .catch(function (error) {
                        Swal("Error4", error.response.data.Message, "error");
                    });
            }
        });
    }

    autoSave = (change_type) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => this.sendSave(change_type), 750);
    }

    sendSave = (change_type) => {
        var segments = JSON.parse(JSON.stringify(this.state.segments));
        var self = this;
        var req_id = (Math.random() + 1).toString(36).substring(7);
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/loads/0', {
            errors: [],
            warnings: [],
            customer_id: this.state.customer_id,
            segments: segments,
            driver_id: this.state.driver_id,
            description: this.state.description,
            vehicle_type_id: this.state.vehicle_type_id,
            vehicle_type_sub_id: this.state.vehicle_type_sub_id,
            vehicle_id: this.state.vehicle_id,
            equipment_type_id: this.state.equipment_type_id,
            equipment_type_sub_id: this.state.equipment_type_sub_id,
            equipment_id: this.state.equipment_id,
            rate_tolls_billable: parseFloat(this.state.rate_tolls_billable),
            qty_drops: parseFloat(this.state.qty_drops),
            qty_hrs_total: parseFloat(this.state.qty_hrs_total),
            qty_hrs_total_billable: parseFloat(this.state.qty_hrs_total_billable),
            qty_hrs_wait: parseFloat(this.state.qty_hrs_wait),
            qty_hrs_wait_billable: parseFloat(this.state.qty_hrs_wait_billable),
            labour_rates: this.state.labour_rates,
            req_id: req_id,
            price_per_litre: parseFloat(this.state.price_per_litre),
            mpg: parseFloat(this.state.mpg),
            vehicle_cost_day: parseFloat(this.state.vehicle_cost_day),
            vehicle_cost_km: parseFloat(this.state.vehicle_cost_km),
            total_vehicle_cost: parseFloat(this.state.total_vehicle_cost),
            man_total_vehicle_cost: this.state.man_total_vehicle_cost,
            total_admin_cost: parseFloat(this.state.total_admin_cost),
            man_total_admin_cost: this.state.man_total_admin_cost,
            man_tolls: this.state.man_tolls,
            change_type: change_type
        })
            .then(function (response) {
                self.setState({
                    qty_miles: response.data.totals.qty_miles,
                    qty_drops: response.data.totals.qty_drops,
                    total_fixed_rate: response.data.totals.total_fixed_rate,
                    total_variable_rate: response.data.totals.total_variable_rate,
                    total_variable_fsc: response.data.totals.total_variable_fsc,
                    total_fixed_fsc: response.data.totals.total_fixed_fsc,
                    total_other: response.data.totals.total_other,
                    total_subtotal: response.data.totals.total_subtotal,
                    total_tax: response.data.totals.total_tax,
                    total_total: response.data.totals.total_total,
                    labour_miles: response.data.totals.labour_miles,
                    labour_hr: response.data.totals.labour_hr,
                    labour_drops: response.data.totals.labour_drops,
                    total_labour: response.data.totals.total_labour,
                    total_margin: response.data.totals.total_margin,
                    total_vehicle_cost: response.data.totals.total_vehicle_cost,
                    total_admin_cost: response.data.totals.total_admin_cost,
                    qty_miles_billable: response.data.totals.qty_miles_billable,
                    rate_tolls_billable: response.data.totals.rate_tolls_billable,
                    qty_hrs_total: response.data.totals.qty_hrs_total,
                    qty_hrs_total_billable: response.data.totals.qty_hrs_total_billable,
                    rate_tolls: response.data.totals.rate_tolls,
                    labour_rates: response.data.totals.labour_rates
                });
                self.getSegments();
                if (response.data.req_id == req_id) {
                    toast.success("Changes Saved", {
                        position: toast.POSITION.BOTTOM_RIGHT
                    });
                }
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error5", error.response.data.Message, "error");
                    }
                }
            });
    }

    // Start Select specific
    formatGroupLabel = (data) => (
        <div className="justify-content border-bottom">
            <span className="text-muted">{data.name}</span>
            <div className="small float-right"><span className="badge badge-secondary" style={{padding: "2px"}}>{data.options.length}</span></div>
        </div>
    );

    render() {
        var total_subtotal = money.floatToAmount(0);
        var total_fuel = money.mul(money.floatToAmount(this.state.price_per_litre / 0.26417205), money.div(money.floatToAmount(this.state.qty_miles), money.floatToAmount(this.state.mpg)));
        var total_margin = money.floatToAmount(this.state.total_labour);
        total_margin = money.add(total_margin, money.floatToAmount(this.state.rate_tolls));
        total_margin = money.add(total_margin, total_fuel);
        total_margin = money.add(total_margin, money.floatToAmount(this.state.total_vehicle_cost));
        total_margin = money.add(total_margin, money.floatToAmount(this.state.total_admin_cost));

        var selectTargetHeight = 34;
        var selectStyles = {
            control: (base) => ({
                ...base,
                minHeight: 'initial',
            }),
            valueContainer: (base) => ({
                ...base,
                height: `${selectTargetHeight - 1 - 1}px`,
                padding: '0 8px',
                fontSize: '13px',
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: `${(selectTargetHeight - 20 - 1 - 1) / 2}px`,
            }),
            dropdownIndicator: (base) => ({
                ...base,
                padding: `${(selectTargetHeight - 20 - 1 - 1) / 2}px`,
            }),
            groupHeading: (base) => ({
                ...base,
                flex: '1 1',
                color: 'white',
                margin: 0,
            }),
        };
        var selectStylesEquip = {
            control: (base) => ({
                ...base,
                backgroundColor: `#f8f9fa`,
                borderColor: '#f8f9fa',
                minHeight: 'initial',
                minWidth: "110px"
            }),
            valueContainer: (base) => ({
                ...base,
                height: `${selectTargetHeight - 1 - 1}px`,
                padding: '0 8px',
                fontSize: '13px',
            }),
            clearIndicator: (base) => ({
                ...base,
                padding: `${(selectTargetHeight - 20 - 1 - 1) / 2}px`,
            }),
            dropdownIndicator: (base) => ({
                display: `none !important`,
            }),
            indicatorSeparator: (base) => ({
                display: `none !important`,
            }),
            groupHeading: (base) => ({
                ...base,
                flex: '1 1',
                color: 'white',
                margin: 0,
            }),
            menu: (base) => ({
                ...base,
                width: '300px',
            }),
        };
        var MenuListEquipment = (props) => {
            return (
                <components.MenuList {...props}>
                    {props.children}
                </components.MenuList>
            )
        }
        var MenuListVehicle = (props) => {
            return (
                <components.MenuList {...props}>
                    <div className="bg-info text-light rounded ml-1 mr-1 p-1 pl-2 small" style={{margin: "0px", cursor: "pointer"}} onClick={() => {
                        this.setState({trigger_open_vehicle: moment().unix()})
                    }}>
                        Add New Vehicle
                    </div>
                    {props.children}
                </components.MenuList>
            )
        }

        const filterOptions = (candidate, search_term) => {
            if (search_term) {
                return candidate.data.label.includes(search_term)
            }
            return true
        }

        var target_margin = this.state.target_margin;
        var markup_cost = money.mul(total_margin, money.floatToAmount((target_margin / 100) + 1));

        return (
            <div>
                <ContentWrapper>
                    <div className="content-heading pr-2 mb-1">
                        <div className="row w-100 pr-0">
                            <div className="col-6">
                                Calculate Quote
                            </div>
                        </div>
                    </div>
                    <Card className="card card-default">
                        <CardBody>
                            <form className={(this.state.page == "details" ? "form-horizontal" : "d-none")}>
                                <FormGroup row>
                                    <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Vehicle:</Col>
                                    <Col xl="11" lg="10" sm="9">
                                        <table className="table table-sm">
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle disabled={this.state.status == 4} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                            {(this.state.vehicle_type_id == null ? "(select)" : this.state.equipment_types[this.state.vehicle_type_id].name)}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => this.changeVehicleType(null)}>(select)</DropdownItem>
                                                            <DropdownItem divider/>
                                                            {Object.keys(this.state.vehicle_types).map((key, i) => {
                                                                    if (typeof this.state.vehicle_types != "undefined" && typeof this.state.vehicle_types[key] != "undefined" && this.state.vehicle_types[key].is_vehicle == 1) {
                                                                        return (<DropdownItem key={key}
                                                                                              onClick={() => this.changeVehicleType(key)}>{this.state.vehicle_types[key].name}</DropdownItem>)
                                                                    }
                                                                }
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                    <i className="fas fa-angle-right ml-2 mr-2"></i>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle disabled={this.state.status == 4} color="secondary" caret style={{fontSize: '13px', width: 'auto'}}>
                                                            {(this.state.vehicle_type_sub_id == null ? "(select)" : this.state.equipment_types_sub[this.state.vehicle_type_sub_id].name)}
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={() => this.changeVehicleTypeSub(null)}>(select)</DropdownItem>
                                                            <DropdownItem divider/>
                                                            {Object.keys(this.state.vehicle_types_sub).map((key, i) => {
                                                                    if (typeof this.state.vehicle_types_sub != "undefined" && typeof this.state.vehicle_types_sub[key] != "undefined" && this.state.vehicle_type_id == this.state.vehicle_types_sub[key].equipment_type_id) {
                                                                        return (
                                                                            <DropdownItem key={key}
                                                                                          onClick={() => this.changeVehicleTypeSub(key)}>{this.state.vehicle_types_sub[key].name}</DropdownItem>
                                                                        )
                                                                    }
                                                                }
                                                            )}
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </td>
                                                <td>
                                                    <div className={(this.state.loading_equipment ? "" : "d-none")}><i className="fa fa-spin fa-spinner"></i></div>
                                                    <div className={(this.state.loading_equipment ? "d-none" : "")}>
                                                        <Select
                                                            value={{
                                                                value: this.state.vehicle_id,
                                                                label: (typeof this.state.equipment[this.state.vehicle_id] == "undefined" ? "(select)" : this.state.equipment[this.state.vehicle_id].name),
                                                                type: "",
                                                                status: 1,
                                                                dwell: ""
                                                            }}
                                                            options={this.state.vehicle_sort}
                                                            onChange={this.changeVehicle}
                                                            formatGroupLabel={this.formatGroupLabel}
                                                            closeMenuOnSelect={true}
                                                            styles={selectStyles}
                                                            components={{MenuList: MenuListVehicle}}
                                                            getOptionLabel={(option) => <span><span
                                                                className={(option.status == 2 ? "text-decoration-line-through" : "")}>{`${option.label}`}</span> <small
                                                                className={(option.status == 2 ? "text-danger" : "d-none")}>OOS</small><small
                                                                className={(option.status == 2 ? "d-none" : "text-muted")}>{`${option.dwell}`}</small></span>}
                                                            isClearable={true}
                                                            isDisabled={this.state.status == 4}
                                                            filterOption={filterOptions}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="pt-2">
                                    <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Trips:</Col>
                                    <Col xl="11" lg="10" sm="9">
                                        <table className={(this.state.loading_segments ? "table table-sm table-borderless whirl traditional" : "table table-sm")}>
                                            <thead>
                                            <tr className="thead-light">
                                                <th>Origin</th>
                                                <th>Destination</th>
                                                <th>Equipment</th>
                                                <th>Miles</th>
                                                <th>Driving (hrs)</th>
                                                <th>Wait (hrs)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.segments.map(function (segment, segment_num) {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td className="border-0 pborder-0 pt-2">
                                                                <div style={{position: "absolute", left: "-5px", marginTop: "5px"}}
                                                                     className="badge badge-secondary">{segment.segment_num + 1}</div>
                                                                <Button color="light" size="sm" disabled={this.state.status == 4} onClick={() => {
                                                                    this.location_search_toggle(segment_num, 'origin')
                                                                }}>{(segment.origin == null ? "(select)" : this.state.locations[segment.origin].name)}</Button></td>
                                                            <td className="border-0 pborder-0 pt-2"><Button color="light" size="sm" disabled={this.state.status == 4}
                                                                                                            onClick={() => {
                                                                                                                this.location_search_toggle(segment_num, 'destination')
                                                                                                            }}>{(segment.destination == null ? "(select)" : this.state.locations[segment.destination].name)}</Button>
                                                            </td>
                                                            <td className="border-0 pborder-0 pt-2">
                                                                <div className={(this.state.loading_equipment ? "" : "d-none")}><i className="fa fa-spin fa-spinner"></i></div>
                                                                <div className={(this.state.loading_equipment ? "d-none" : "")}>
                                                                    <Select
                                                                        value={{
                                                                            value: this.state.equipment_id,
                                                                            label: (typeof this.state.equipment[segment.equipment_id] == "undefined" ? "(select)" : this.state.equipment[segment.equipment_id].name),
                                                                        }}
                                                                        options={this.state.equipment_sort}
                                                                        onChange={(event) => this.changeEquipment(segment_num, event)}
                                                                        formatGroupLabel={this.formatGroupLabel}
                                                                        closeMenuOnSelect={true}
                                                                        styles={selectStylesEquip}
                                                                        components={{MenuList: MenuListEquipment}}
                                                                        onClick={(event) => this.setState({new_equip_segment_num: segment.segment_num})}
                                                                        getOptionLabel={(option) => <span><span
                                                                            className={(option.status == 2 ? "text-decoration-line-through" : "")}>{`${option.label}`}</span></span>}
                                                                        isClearable={true}
                                                                        filterOption={filterOptions}
                                                                        new_equip_segment_num={segment.segment_num}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="border-0 pborder-0 pt-2">
                                                                <div className={(segment.origin != null && segment.destination != null ? "" : "d-none")}>
                                                                    <input type="number" className="form-control form-control-sm" value={segment.distance_miles}
                                                                           style={{minWidth: "65px"}}
                                                                           disabled={this.state.status == 4}
                                                                           onChange={(event) => this.updateFieldSegment(segment_num, "distance_miles", event)}/>
                                                                    <div className="small text-muted">Calc: <i
                                                                        className={segment.distance_miles_computed == null ? "fas fa-sync fa-pulse" : "d-none"}></i>{segment.distance_miles_computed} mi
                                                                    </div>
                                                                </div>
                                                                <div className={(segment.origin != null && segment.destination != null ? "d-none" : "")}>
                                                                    ---
                                                                </div>
                                                            </td>
                                                            <td className="border-0 pborder-0 pt-2">
                                                                <div className={(segment.origin != null && segment.destination != null ? "" : "d-none")}>
                                                                    <input type="number" className="form-control form-control-sm" value={segment.duration_hrs}
                                                                           style={{minWidth: "65px"}}
                                                                           disabled={this.state.status == 4}
                                                                           onChange={(event) => this.updateFieldSegment(segment_num, "duration_hrs", event)}/>
                                                                    <div className="small text-muted">Calc: <i
                                                                        className={segment.distance_miles_computed == null ? "fas fa-sync fa-pulse" : "d-none"}></i>{segment.duration_hrs_computed} hrs
                                                                    </div>
                                                                </div>
                                                                <div className={(segment.origin != null && segment.destination != null ? "d-none" : "")}>
                                                                    ---
                                                                </div>
                                                            </td>
                                                            <td className="border-0 pborder-0 pt-2">
                                                                <input type="number" className="form-control form-control-sm" value={segment.wait_hrs}
                                                                       disabled={this.state.status == 4}
                                                                       style={{minWidth: "65px"}}
                                                                       onChange={(event) => this.updateFieldSegment(segment_num, "wait_hrs", event)}/>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="8" className="pt-0 pborder-0 border-0">
                                                                <Row className="bg-light m-0 pt-1 pborder-0 pl-0 pr-0">
                                                                    <Col className="pl-1">
                                                                        <Select
                                                                            value={{
                                                                                value: segment.driver_id,
                                                                                label: (segment.driver_id == null ? "(select)" : this.state.drivers[segment.driver_id].first_name + " " + this.state.drivers[segment.driver_id].last_name),
                                                                            }}
                                                                            options={this.state.drivers_sort}
                                                                            onChange={(event) => this.changeDriver(segment_num, event)}
                                                                            closeMenuOnSelect={true}
                                                                            styles={selectStyles}
                                                                            isClearable={true}
                                                                            isDisabled={this.state.status == 4}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={3} md={3} lg={2} className="valign-bottom pt-1">
                                                                        <label className={(segment.billable == 1 ? "" : "text-danger")}>
                                                                            <Input type="checkbox" name="avoid_borderCrossing" value="1"
                                                                                   checked={(segment.billable == 1)}
                                                                                   disabled={this.state.status == 4}
                                                                                   onChange={(event) => this.updateFieldSegment(segment_num, "billable", event)}/>
                                                                            Billable Trip <i className={(segment.billable == 1 ? "d-none" : "fas fa-exclamation-circle")}></i>
                                                                        </label>
                                                                    </Col>
                                                                    <Col sm={1} md={1} lg={1} className="text-right">
                                                                        <div className={(this.state.status == 4 ? "d-none" : "btn btn-danger btn-xs")}
                                                                             onClick={() => this.deleteSegment(segment.segment_id)}><i
                                                                            className="far fa-trash-alt"></i>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            }, this)}
                                            <tr>
                                                <td colSpan="8" className="border-0 border-top-0">
                                                    <div className={(this.state.status == 4 ? "d-none" : "btn btn-xs btn-info")} onClick={(event) => this.addSegment(false)}>+ Add
                                                        Trip
                                                    </div>
                                                    <div className={(this.state.status == 4 ? "d-none" : "btn btn-xs btn-info ml-2")}
                                                         onClick={(event) => this.addSegment(true)}>+ <i
                                                        className="fa-solid fa-rotate-left"></i> Return Trip
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Driver Pay:</Col>
                                    <Col xl="11" lg="10" sm="9">
                                        <Row className="no-gutters">
                                            {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                                return (
                                                    <>
                                                        <Col className="bg-light pb-1 pl-2 pr-2 mr-1 rounded" style={{minWidth: "350px"}}>
                                                            <small>{this.state.drivers[driver_id].first_name} {this.state.drivers[driver_id].last_name}</small>
                                                            <Row>
                                                                <Col className="pr-2">
                                                                    <InputGroup size="sm">
                                                                        <div className="input-group-append"><span className="input-group-text pl-2 pr-2 small">$</span></div>
                                                                        <Input type="text" className="text-right" name="rate_miles" disabled={this.state.status == 4}
                                                                               value={this.state.labour_rates[driver_id].rate_miles}
                                                                               onChange={(e) => this.updatePayment(driver_id, 'rate_miles', e)}/>
                                                                        <div className="input-group-append"><span className="input-group-text p-1 small">/mi</span></div>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col className="p-0">
                                                                    <InputGroup size="sm">
                                                                        <div className="input-group-append"><span className="input-group-text pl-2 pr-2 small">$</span></div>
                                                                        <Input type="text" className="text-right" name="rate_hr" disabled={this.state.status == 4}
                                                                               value={this.state.labour_rates[driver_id].rate_hr}
                                                                               onChange={(e) => this.updatePayment(driver_id, 'rate_hr', e)}/>
                                                                        <div className="input-group-append"><span className="input-group-text p-1 small">/hr</span></div>
                                                                    </InputGroup>
                                                                </Col>
                                                                <Col className="pl-2">
                                                                    <InputGroup size="sm">
                                                                        <div className="input-group-append"><span className="input-group-text pl-2 pr-2 small">$</span></div>
                                                                        <Input type="text" className="text-right" name="rate_drops" disabled={this.state.status == 4}
                                                                               value={this.state.labour_rates[driver_id].rate_drops}
                                                                               onChange={(e) => this.updatePayment(driver_id, 'rate_drops', e)}/>
                                                                        <div className="input-group-append"><span className="input-group-text p-1 small">/drop</span></div>
                                                                    </InputGroup>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            target={"driver" + driver_id}
                                                        >
                                                            {this.state.drivers[driver_id].first_name} {this.state.drivers[driver_id].last_name}
                                                        </UncontrolledTooltip>
                                                    </>
                                                )
                                            }, this)}
                                        </Row>
                                    </Col>
                                </FormGroup>
                                <FormGroup row className="pt-2">
                                    <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Route:</Col>
                                    <Col xl="11" lg="10" sm="9" style={{minHeight: "400px"}}>
                                        <MapLoad points={this.state.points} travel_line={this.state.linestring}
                                                 reference_line={this.state.linestring}/>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Costs:</Col>
                                    <Col xl="11" lg="10" sm="9">
                                        <table className="table table-sm">
                                            <thead>
                                            <tr className="thead-light">
                                                <th>Type</th>
                                                <th></th>
                                                <th className="text-right pr-3">Cost</th>
                                                <th></th>
                                                <th className="text-right pr-3">Qty</th>
                                                <th></th>
                                                <th className="text-right">Subtotal</th>
                                                <th className="text-right">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <th className="border-0"></th>
                                                <td className="border-0">Labour, per Mile</td>
                                                <td className="border-0 text-right">
                                                    {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                                        return (
                                                            <>
                                                        <span id={"driver" + driver_id}
                                                              style={{borderBottom: "1px dashed #999"}}>${money.format('USD', money.floatToAmount(this.state.labour_rates[driver_id].rate_miles))}</span>
                                                                <span className={(i == Object.keys(this.state.labour_rates).length - 1 ? "d-none" : "")}> / </span>
                                                            </>
                                                        )
                                                    }, this)}
                                                </td>
                                                <td className="border-0 text-center">x</td>
                                                <td className="border-0 text-right">{this.state.qty_miles}</td>
                                                <td className="border-0 text-center">=</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.labour_miles))}</td>
                                                <td className="border-0"></td>
                                            </tr>
                                            <tr>
                                                <th className="border-0"></th>
                                                <td className="border-0">Labour, per Hour</td>
                                                <td className="border-0 text-right">
                                                    {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                                        return (<>
                                                    <span id={"driver" + driver_id}
                                                          style={{borderBottom: "1px dashed #999"}}>${money.format('USD', money.floatToAmount(this.state.labour_rates[driver_id].rate_hr))}</span>
                                                            <span className={(i == Object.keys(this.state.labour_rates).length - 1 ? "d-none" : "")}> / </span>
                                                        </>)
                                                    }, this)}
                                                </td>
                                                <td className="border-0 text-center">x</td>
                                                <td className="border-0 text-right">{money.format('USD', money.floatToAmount(parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait)))}</td>
                                                <td className="border-0 text-center">=</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.labour_hr))}</td>
                                                <td className="border-0"></td>
                                            </tr>
                                            <tr>
                                                <th className="border-0"></th>
                                                <td className="border-0">Labour, Drop Fee</td>
                                                <td className="border-0 text-right">
                                                    {Object.keys(this.state.labour_rates).map((driver_id, i) => {
                                                        return (<>
                                                    <span id={"driver" + driver_id}
                                                          style={{borderBottom: "1px dashed #999"}}>${money.format('USD', money.floatToAmount(this.state.labour_rates[driver_id].rate_drops))}</span>
                                                            <span className={(i == Object.keys(this.state.labour_rates).length - 1 ? "d-none" : "")}> / </span>
                                                        </>)
                                                    }, this)}
                                                </td>
                                                <td className="border-0 text-center">x</td>
                                                <td className="border-0 text-right">{this.state.qty_drops}</td>
                                                <td className="border-0 text-center">=</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.labour_drops))}</td>
                                                <td className="border-0"></td>
                                            </tr>
                                            <tr>
                                                <th colSpan="7" className="border-top-0">
                                                    Total Labour
                                                </th>
                                                <th className="border-top-0 text-right">
                                                    ${money.format('USD', money.floatToAmount(this.state.total_labour))}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colSpan="7">
                                                    Tolls
                                                </th>
                                                <th className="text-right">
                                                    ${money.format('USD', money.floatToAmount(this.state.rate_tolls))}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th colSpan="2">Fuel</th>
                                                <td className="text-right">${money.format('USD', money.floatToAmount(this.state.price_per_litre))}/L</td>
                                                <td className="text-center">x</td>
                                                <td className="text-right">{this.state.qty_miles} mi. @ {this.state.mpg} mpg</td>
                                                <td className="text-center">=</td>
                                                <td></td>
                                                <th className="text-right">${money.format('USD', money.floatToAmount(total_fuel))}</th>
                                            </tr>
                                            <tr>
                                                <th className="border-0"></th>
                                                <td className="border-0">Vehicle Cost, per KM</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.vehicle_cost_km))}</td>
                                                <td className="border-0 text-center">x</td>
                                                <td className="border-0 text-right">{Math.round(this.state.qty_miles * 1.609344)}</td>
                                                <td className="border-0 text-center">=</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.qty_miles * 1.609344 * this.state.vehicle_cost_km))}</td>
                                                <td className="border-0"></td>
                                            </tr>
                                            <tr>
                                                <th className="border-0"></th>
                                                <td className="border-0">Vehicle Cost, per Day</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.vehicle_cost_day))}</td>
                                                <td className="border-0 text-center">x</td>
                                                <td className="border-0 text-right">{(parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12 ? 1 : .5)}</td>
                                                <td className="border-0 text-center">=</td>
                                                <td className="border-0 text-right">${money.format('USD', money.floatToAmount(this.state.vehicle_cost_day * (parseFloat(this.state.qty_hrs_total) + parseFloat(this.state.qty_hrs_wait) > 12 ? 1 : .5)))}</td>
                                                <td className="border-0"></td>
                                            </tr>
                                            <tr>
                                                <th colSpan="7" className="border-top-0">Vehicle Cost</th>
                                                <td className="text-right border-top-0">
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                        <Input type="text" name="total_vehicle_cost" className="text-right" disabled={this.state.status == 4}
                                                               value={this.state.total_vehicle_cost}
                                                               onChange={this.updateValue}/>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th colSpan="7" className="border-top-0">Admin Cost</th>
                                                <td className="text-right border-top-0">
                                                    <InputGroup size="sm">
                                                        <InputGroupAddon addonType="append">$</InputGroupAddon>
                                                        <Input type="text" name="total_admin_cost" className="text-right" disabled={this.state.status == 4}
                                                               value={this.state.total_admin_cost}
                                                               onChange={this.updateValue}/>
                                                    </InputGroup>
                                                </td>
                                            </tr>
                                            </tbody>
                                            <tfoot>
                                            <tr>
                                                <th colSpan="7" className="text-right">
                                                    TOTAL COST:
                                                </th>
                                                <th className="text-right">
                                                    <u>${money.format('USD', total_margin)}</u>
                                                </th>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col xl="1" lg="2" sm="3" className="small text-uppercase pt-2">Margin:</Col>
                                    <Col xl="11" lg="10" sm="9">
                                        Target Margin:
                                        <InputGroup style={{maxWidth: "200px"}}>
                                            <Input type="text" className="text-right" name="target_margin" value={this.state.target_margin}
                                                   onChange={this.updateValue}/>
                                            <InputGroupAddon addonType="append">%</InputGroupAddon>
                                        </InputGroup>
                                        <Row className="h4 mt-3">
                                            <Col md={5} lg={2}>
                                                By Mile:<br/>
                                                ${money.format('USD', money.div(markup_cost, money.floatToAmount(this.state.qty_miles_billable)))}
                                            </Col>
                                            <Col md={3} lg={1}><small className="text-muted">OR</small></Col>
                                            <Col md={3} lg={2}>
                                                By Hour:<br/>
                                                ${money.format('USD', money.div(markup_cost, money.add(money.floatToAmount(this.state.qty_hrs_wait_billable), money.floatToAmount(this.state.qty_hrs_total_billable))))}
                                            </Col>
                                            <Col md={1} lg={7}></Col>
                                        </Row>
                                    </Col>
                                </FormGroup>
                            </form>
                        </CardBody>
                    </Card>
                    <Modal isOpen={this.state.location_search_modal} fade={false} toggle={() => {
                        this.location_search_toggle(false, false)
                    }} size="sm">
                        <ModalHeader>
                            <div className="h3 mborder-0">Search Saved Location</div>
                        </ModalHeader>
                        <ModalBody>
                            <input type="text" className="form-control form-control-lg mb-1" id="location_search" name="search_term" value={this.state.search_term}
                                   placeholder="Search by Location Name or Address" onChange={this.updateValue}/>
                            <ul className="list-group" style={{maxHeight: 'calc(100vh - 120px)', overflowY: "auto"}}>
                                <AddLocation location_saved={this.location_saved} button={false} extraClass={"list-group-item bg-info btn btn-info"}/>
                                {this.state.locations_sort.map(function (item, key) {
                                    if (this.state.search_term == "" || item.name.toLowerCase().includes(this.state.search_term.toLowerCase()) || item.address.toLowerCase().includes(this.state.search_term.toLowerCase()) || item.city.toLowerCase().includes(this.state.search_term.toLowerCase())) {
                                        return (
                                            <div key={key} className="list-group-item btn btn-light text-left" onClick={() => {
                                                this.updateLocation(this.state.temp_segment, this.state.temp_point, item.location_id)
                                            }}>
                                                {item.name}
                                                <div className="small">{item.address}, {item.city}</div>
                                            </div>
                                        )
                                    }
                                }, this)}
                            </ul>
                        </ModalBody>
                    </Modal>
                </ContentWrapper>
            </div>
        );
    }
}

export default QuoteDisplay;
