import React from "react";

class LoadStatus extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status_print: "",
            status_css: ""
        };
    }

    componentDidMount() {
        this.update_badge()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.status !== this.props.status) {
            this.update_badge()
        }
    }

    update_badge() {
        if (this.props.status == 0) {
            var status_print = "Draft";
            var status_css = "light";
        } else if (this.props.status == 1) {
            var status_print = "Scheduled";
            var status_css = "info";
        } else if (this.props.status == 2) {
            var status_print = "Started";
            var status_css = "warning";
        } else if (this.props.status == 3) {
            var status_print = "Completed";
            var status_css = "success";
        } else {
            var status_print = "Billed";
            var status_css = "dark";
        }
        this.setState({
            status_print: status_print,
            status_css: "badge badge-" + status_css
        });
    }

    render() {
        return (
            <div className={this.state.status_css}>{this.state.status_print}</div>
        );
    }
}

export default LoadStatus;