
import React, {Component} from 'react';
import {BrowserRouter} from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
import {API_ROOT} from './api-config';
import SockJS from 'sockjs-client'
import Geolocation from '@react-native-community/geolocation';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import Swal from "sweetalert2";

// Disable warning "Synchronous XMLHttpRequest on the main thread is deprecated.."
$.ajaxPrefilter(o => o.async = true);

class App extends Component {
    //login
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            first_name: '',
            last_name: '',
            role_id: '',
            num_unread: null,
            num_unread_maint: null,
            last_updated_thread: 0,
            last_updated_hash: 0,
            isConnected: false,
            messages: {
                list: [],
                loading: true,
                subscribed: []
            },
            messages_maint: {
                list: [],
                loading: true,
            },
        };
        this.refresh_from_local = this.refresh_from_local.bind(this)
    }

    async componentDidMount() {
        this.userHasAuthenticated(false);
        var self = this;
        self.refresh_from_local(); // trigger immediate reload from local storage

        if (typeof localStorage.getItem('permissions') != "undefined" && localStorage.getItem('permissions') != null && (localStorage.getItem('permissions').indexOf('DI') != -1 || localStorage.getItem('permissions') == "ALL")) {
            this.connectWebSocket();
        }

        setTimeout(() => {
            // If after 2sec there are no messages, refresh
            if (this.state.messages.list.length == 0) {
                this.setState({first_name: 'TEST'});
                this.refreshMessages();
            }
        }, 2000);

        if (localStorage.getItem('first_name') !== null) {
            axios.defaults.withCredentials = true;
            axios.post(`${API_ROOT}/heartbeat`, {withCredentials: true})
                .then(function (response) {
                    self.userHasAuthenticated(true);
                    self.setState({isAuthenticating: false});
                    self.refresh_from_local()
                    self.report_location();
                })
                .catch(function (error) {
                    self.userSignOut()
                    self.setState({isAuthenticating: false});
                });
        } else {
            self.userSignOut();
            this.setState({isAuthenticating: false});
        }

        // send current location
        setInterval(async () => {
            this.report_location();
        }, 60 * 1000);

        setInterval(async () => {
            if (this.state.num_unread > 0) {
                document.title = "(" + this.state.num_unread + ") " + document.title.replace(/^\([^)]*\)\s*/, '');
            } else {
                document.title = document.title.replace(/^\([^)]*\)\s*/, '');
            }
        }, 1000);
    }

    connectWebSocket() {
        const sock = new SockJS('/api/msg-socket');

        sock.onopen = () => {
            this.setState({isConnected: true}); // Set the connection status to true
            this.refreshMessages();
        };

        sock.onmessage = (e) => {
            this.setState({
                last_updated_thread: e.data,
                last_updated_hash: this.state.last_updated_hash + 1,
            });
            this.refreshMessages();
        };

        sock.onclose = () => {
            this.setState({isConnected: false}); // Set the connection status to false
            // Reconnect after a delay (e.g., 5 seconds)
            setTimeout(() => {
                this.connectWebSocket();
            }, 2000);
        };
    }

    report_location() {
        if (this.state.isAuthenticated == true) {
            /*Geolocation.getCurrentPosition(
                (position) => {
                    var coords = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        speed: position.coords.speed,
                        accuracy: position.coords.accuracy,
                    };
                    axios.defaults.withCredentials = true;
                    axios.post(`${API_ROOT}/ping`, {coords})
                        .catch(function (error) {
                        });
                },
                (error) => {
                    // See error code charts below.
                    console.log(error.code, error.message);
                },
                {enableHighAccuracy: true, timeout: 15000, maximumAge: 10000}
            );*/
        }
    }

    refresh_from_local() {
        this.setState({
            first_name: localStorage.getItem('first_name'),
            last_name: localStorage.getItem('last_name'),
            role_id: localStorage.getItem('role_id'),
            permissions: localStorage.getItem('permissions'),
            companies: localStorage.getItem('companies'),
        });
    }

    userHasAuthenticated = authenticated => {
        this.setState({isAuthenticated: authenticated});
    }
    userSignOut = event => {
        axios.defaults.withCredentials = true;
        axios.post(`${API_ROOT}/logout`, {withCredentials: true})
        localStorage.removeItem('first_name');
        localStorage.removeItem('last_name');
        localStorage.removeItem('role_id');
        localStorage.removeItem('permissions');
        localStorage.removeItem('companies');
        this.userHasAuthenticated(false);
    }

    refreshMessages = () => {
        var self = this;
        axios.get(API_ROOT + '/messages')
            .then(function (response) {
                var num_unread = 0;
                var num_unread_maint = 0;
                for (const thread of response.data.messages) {
                    if (thread.num_unread > 0) {
                        num_unread = num_unread + thread.num_unread;
                    }
                }
                for (const thread of response.data.messages_maint) {
                    if (thread.num_unread > 0) {
                        num_unread_maint = num_unread_maint + thread.num_unread_maint;
                    }
                }
                if (
                    (self.state.num_unread != null && num_unread > self.state.num_unread) ||
                    (self.state.num_unread_maint != null && num_unread_maint > self.state.num_unread_maint)
                ) {
                    new Audio("newmessage.mp3").play();
                }
                self.setState({
                    messages: {
                        list: response.data.messages,
                        subscribed: response.data.subscribed.split(','),
                        loading: false,
                    },
                    messages_maint: {
                        list: response.data.messages_maint,
                        loading: false,
                    },
                    num_unread: num_unread,
                    num_unread_maint: num_unread_maint
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                }
            });
    }

    render() {

        // specify base href from env varible 'WP_BASE_HREF'
        // use only if application isn't served from the root
        // for development it is forced to root only
        /* global WP_BASE_HREF */
        const basename = '/';

        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated,
            userSignOut: this.userSignOut,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            role_id: this.state.role_id,
            refresh_from_local: this.refresh_from_local,
            num_unread: this.state.num_unread,
            num_unread_maint: this.state.num_unread_maint,
            messages: this.state.messages,
            messages_maint: this.state.messages_maint,
            last_updated_thread: this.state.last_updated_thread,
            last_updated_hash: this.state.last_updated_hash,
            refreshMessages: this.refreshMessages,
        };


        return (
            !this.state.isAuthenticating &&
            <BrowserRouter basename={basename}>
                <Routes childProps={childProps}/>
            </BrowserRouter>
        );
    }
}

export default App;
