let backendHost;

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
    backendHost = 'http://localhost:9090';
} else if (hostname === 'greenhaven.thewebpeople.ca') {
    backendHost = 'https://greenhaven.thewebpeople.ca';
} else {
    backendHost = 'https://app.ghtranspo.com/';
}

export const API_ROOT = `${backendHost}/api`;