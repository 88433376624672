import React, {Component} from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input
} from 'reactstrap';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import "react-datepicker/dist/react-datepicker.css";
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';

class AddPhoto extends React.Component {
    constructor(props) {
        super(props);
        window.AddCallNotes = this;
        this.addPhoto = this.addPhoto.bind(this);

        this.state = {
            modal: false,
            notes: " ",
            responses: {},
            call_id: false,
            photos: [],
            processing: false
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    selectCallNotes = (name, value) => {
        if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        });
    };

    addPhoto = (hash) => {
        var photos = this.state.photos;
        photos.push(hash);
        this.props.addPhotos(hash);
        this.setState({
            photos: photos,
            modal: false
        });
    }

    render() {
        return (
            <div className="float-right">
                <Button color="primary" size="xs" className="mb-2" onClick={this.toggle}><i className="fas fa-plus"></i> Photo</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <ModalHeader>
                        <div className="h3 mb-0">Add Photo</div>
                    </ModalHeader>
                    <ModalBody>
                        <div className="text-bold h4 pb-0 mb-0 pt-2">Description</div>
                        <Input type="textarea" name="notes" value={this.state.notes} onChange={(event) => this.selectCallNotes("notes", event)}/>
                        <div className="text-bold h4 pb-0 mb-0">Photo</div>
                        <div>
                            <Upload equipment_id={this.props.equipment_id} description={this.state.notes} photos={this.state.photos} addPhoto={this.addPhoto}/>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    };
}

const Upload = (props) => {
    const getUploadParams = ({file, meta}) => {
        const url = API_ROOT + '/photos/' + props.equipment_id + "/" + props.description;
        const body = new FormData();
        body.append('image', file);
        return {url, body}
    };

    const handleChangeStatus = ({meta, remove, xhr}, status) => {
        if (status === 'done') {
            var response = JSON.parse(xhr.response);
            var hash = response.hash;
            props.addPhoto(hash);
        } else if (status === 'headers_received') {
            remove()
        } else if (status === 'aborted') {
            Swal("Error", "Upload Failed", "error");
        }
    };

    return (
        <React.Fragment>
            <Dropzone
                getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                maxFiles={1}
                multiple={false}
                canCancel={false}
                accept="image/*"
                withCredentials={true}
                inputContent={(files, extra) => (extra.reject ? 'JPG or PNG images only' : "Upload a Photo (drag n' drop or click)")}
                styles={{
                    inputLabel: {fontSize: "14px", fontWeight: "normal"},
                    dropzone: {height: 100, minHeight: 75, border: "1px dashed #ccc",},
                    dropzoneActive: {borderColor: 'green'},
                }}
            />
        </React.Fragment>
    )
};

export default (AddPhoto);