import * as React from 'react';

function Landmark(details: { name: null }) {
    var icon = `M172.3 501.7C27 291 0 269.4 0 192 0 86 86 0 192 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.8-29.9 13.8-39.5 0z`;
    var fill = "#007fff";
    var pinStyle = {
        cursor: 'pointer',
        fill: fill,
        stroke: 'none',
    };
    return (
        <div style={{position: "relative", width: "150px", marginTop: "-35px", overflowX: "hidden"}}>
            <div style={{color: "#000", marginBottom: "-4px", textAlign: "center"}}>
                <span className="bg-light rounded" style={{paddingLeft: "2px", paddingRight: "2px"}}>{details.name}</span>
            </div>
            <div style={{paddingLeft: "65px"}}>
                <svg height={20} viewBox="0 0 512 512" style={pinStyle}>
                    <path d={icon}/>
                </svg>
            </div>
        </div>
    );
}

export default React.memo(Landmark);
