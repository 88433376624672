import * as React from 'react';
import {useState, useMemo, useEffect, useRef, useCallback} from 'react';
import Map, {
    Marker,
    Popup,
    NavigationControl,
    ScaleControl,
    Source,
    Layer,
} from 'react-map-gl/maplibre';
import 'maplibre-gl/dist/maplibre-gl.css';

import LoadPin from '../Loads/pin';

import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Halifax");


export function MapLoad(props) {

    const mapRef = useRef(null);
    const [popupInfo, setPopupInfo] = useState(null);

    const setBounds = useCallback((bounds) => {
        mapRef.current?.fitBounds([
            [bounds.x1, bounds.y1],
            [bounds.x2, bounds.y2]
        ], {padding: 50});
    }, []);

    useEffect(() => {
        setPopupInfo(null) // close popup on state change
    }, [props.points])

    useEffect(() => {
        setTimeout(function () {
            var bounds = {x1: false, y1: false, x2: false, y2: false}
            for (var id in props.points) {
                var point = props.points[id];
                if (point.lat < bounds.y1 || bounds.y1 == false) { // sw
                    bounds.y1 = point.lat;
                }
                if (point.lon < bounds.x1 || bounds.x1 == false) { //sw
                    bounds.x1 = point.lon;
                }
                if (point.lat > bounds.y2 || bounds.y2 == false) { //ne
                    bounds.y2 = point.lat;
                }
                if (point.lon > bounds.x2 || bounds.x2 == false) { //ne
                    bounds.x2 = point.lon;
                }
            }
            if (bounds.x1 != false) {
                setBounds(bounds) // use a timeout as it was not reliable without. no idea why
            }
        }, 100);
    }, [props.points])

    useEffect(() => {
        if (typeof props.zoom_to != "undefined" && props.zoom_to !== false && typeof props.zoom_to.longitude != "undefined" && props.zoom_to.longitude != false) {
            mapRef.current?.flyTo({center: [props.zoom_to.longitude, props.zoom_to.latitude], zoom: 14, duration: 1000});
        }
    }, [props.zoom_to])


    var pins = useMemo(
        () =>
            props.points.map((ping, index) => (
                <Marker
                    key={`marker-${index}`}
                    longitude={ping.lon}
                    latitude={ping.lat}
                    anchor={"center"}
                    onClick={e => {
                        // If we let the click event propagates to the map, it will immediately close the popup
                        // with `closeOnClick: true`
                        e.originalEvent.stopPropagation();
                        setPopupInfo(ping);
                    }}
                >
                    <LoadPin type={ping.type} hidden={ping.hidden} bounce={(typeof props.hover_ping != "undefined" && props.hover_ping === ping.timeStamp)}/>
                </Marker>
            )),
        [props.points, props.hover_ping]
    );

    return (
        <>
            <Map
                ref={mapRef}
                initialViewState={{
                    longitude: -64.842166,
                    latitude: 46.085063,
                    zoom: 8,
                    bearing: 0,
                    pitch: 0,
                    touchZoomRotate: true,
                    dragRotate: false,
                    showCompass: false,
                    visualizePitch: false,
                    attributionControl: false,
                }}
                mapStyle="https://api.maptiler.com/maps/streets-v2/style.json?key=6P8HshICDGDXoVd9nuYs"
            >
                <NavigationControl position="top-right" showCompass={false} visualizePitch={false}/>
                <ScaleControl/>
                {pins}
                <Source id="polylineLayer2" type="geojson" data={props.reference_line} key={JSON.stringify(props.reference_line)}>
                    <Layer
                        id="reference_line"
                        type="line"
                        source="my-data2"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": "rgba(0,0,0, 0.2)",
                            "line-width": 2,
                            'line-dasharray': [2, 1],
                        }}
                    />
                </Source>
                <Source id="polylineLayer" type="geojson" data={props.travel_line}
                        key={JSON.stringify(props.travel_line)}>
                    <Layer
                        id="lineLayer"
                        type="line"
                        source="my-data"
                        layout={{
                            "line-join": "round",
                            "line-cap": "round"
                        }}
                        paint={{
                            "line-color": "rgba(255,0,0, 0.6)",
                            "line-width": 3
                        }}
                    />
                </Source>

                {popupInfo && (
                    <Popup
                        anchor="top"
                        longitude={Number(popupInfo.lon)}
                        latitude={Number(popupInfo.lat)}
                        offset={10}
                        onClose={() => setPopupInfo(null)}
                    >
                        <div style={{minWidth: "220px"}}>
                            <div className="small"><span
                                className="badge bg-secondary">{(popupInfo.type == "start" ? "ORIGIN" : (popupInfo.type == "end" ? "DESTINATION" : "STOP"))}</span></div>
                            <div className="h5 mb-0">
                                {popupInfo.name}
                            </div>
                            <div>{popupInfo.address}</div>
                        </div>
                    </Popup>
                )}
            </Map>
        </>
    );
}